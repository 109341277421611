NIRV.refreshNorth = function () {
  DEBUG && console.log(' NIRV.refreshNorth()');

  if (NIRV.q) return;

  NIRV.autosave = false;

  var logo =
    '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 115 9.8" enable-background="new 0 0 115 9.8" xml:space="preserve"><path d="M9.9 0h1.8v9.8H9.9L1.8 2.3l0 1.5v6.1H0V0h1.8l8.1 7.5 0-1.5V0zM19.1 0h1.8v9.8h-1.8V0zM28.3 0h7.8c2.1 0 3.8 0.8 3.8 3.2 0 2.3-1.7 3.1-3.8 3.1h-0.4l4.5 3.6h-2.8l-4.3-3.5h-3.1v3.5h-1.8V0zM36.1 4.6c1.1 0 1.9-0.4 1.9-1.5 0-1.1-0.8-1.5-1.9-1.5h-6v2.9H36.1zM63.3 9.8L69 0l2.1 0 5.7 9.8h-2.1l-2.4-4.2 -2.3-4 -2.3 4 -2.4 4.2H63.3zM101.4 9.8l5.7-9.8 2.1 0 5.7 9.8h-2.1l-2.4-4.2 -2.3-4 -2.3 4 -2.4 4.2H101.4zM59.5 0l-5.7 9.8h-2.1L45.9 0H48l2.4 4.2 2.3 4 2.3-4L57.4 0H59.5zM93.2 0H95v9.8h-1.8L85 2.3l0 1.5v6.1h-1.8V0h1.8l8.1 7.5 0-1.5V0z"/></svg>';

  var focus_q = function () {
    return NIRV.q == '' ? '' : 'focus';
  };

  var currentarea = NIRV.currentarea;
  if (NIRV.currentarea == '__ALL__') currentarea = 'All Areas';
  if (NIRV.currentarea == '__NONE__') currentarea = 'Unassigned';

  $('#north').empty();
  $('#north').append('<span class="left"></span>');

  var areamenu_vibility = NIRV.areas().length > 0 ? 'visible' : 'hidden';
  var highlight = NIRV.currentarea == '__ALL__' ? '' : 'attention';

  // --------------------------
  // NIRVANA LOGO
  $('#north span.left').append('<span class="logo">' + logo + '</span>');

  // AREA MENU
  $('#north span.left').append(
    '       <span class="area x_currentarea xcmenu ' +
      highlight +
      '" style="visibility:' +
      areamenu_vibility +
      ';">' +
      currentarea +
      '</span>'
  );

  // --------------------------
  // NEW ITEM
  $('#north').append(
    '<a class="button newtask multibutton"><u>N</u>ew Item <span class="xcmenu"><span class="chev">▾</span></span></a>'
  );

  // SEARCH BAR
  $('#north').append(
    '<form class="search" action="#" method="get" onsubmit="return false;"><input type="text" class="q ' +
      focus_q() +
      '" name="q" value="' +
      NIRV.q +
      '" autocomplete="off" placeholder="' +
      NIRV.L.search +
      '" tabindex="-1"><i class="search-icon"></i></form>'
  );

  // --------------------------
  // HELP
  // $('#north').append('<a class="right button help"><span class="help">?</span></a>');

  // SETTINGS
  if (NIRV.user.firstname || NIRV.user.lastname) {
    $('#north').append(
      '<a class="right button settingsmenu xcmenu">' +
        NIRV.user.firstname +
        ' ' +
        NIRV.user.lastname +
        '</a>'
    );
  } else {
    $('#north').append(
      '<a class="right button settingsmenu xcmenu">Settings</a>'
    );
  }

  // UPGRADE
  if (NIRV.user.servicelevel == 'basic') {
    $('#north').append(
      '<a class="right button upgrade"><span class="upgrade">Upgrade</span></a>'
    );
  }

  // REFRESH
  $('#north').append(
    '<a class="right button sync"><span><u>R</u>efresh</span><span class="status">•</span></a>'
  );

  // CLEANUP
  $('#north').append(
    '<a class="right button cleanup" title="Move completed items to the Logbook"><span><u>C</u>leanup</span><span class="count"></span></a>'
  );

  NIRV.autosave = true;
};

NIRV.renderSettingsMenu = function () {
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '      <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '      <ul>';
  html += '         <li><span class="label">Nirvana</span></li>';
  html +=
    '         <li><a href="#" fn="NIRV.appSettings">Preferences...<span class="hotkey">comma</span></a></li>';
  html +=
    '         <li><a href="#" fn="NIRV.manageAreas">Manage Tags...<span class="hotkey">G</span></a></li>';
  html +=
    '         <li><a href="#" fn="NIRV.gotoAccount" p1="dashboard">My Account...</a></li>';
  html += '         <li><span class="divider"></span></li>';
  html += '         <li><span class="label">Help</span></li>';
  html +=
    '         <li><a href="#" fn="NIRV.gettingStarted">Getting Started<span class="hotkey">H</span></a></li>';
  html +=
    '         <li><a href="#" fn="NIRV.keyboardShortcuts">Keyboard Shortcuts<span class="hotkey">K</span></a></li>';
  html +=
    '         <li><a href="' +
    NIRV.baseurl_www +
    'go/support" rel="external" target="_blank">Ask us a question</a></li>';
  html +=
    '         <li><a href="' +
    NIRV.baseurl_www +
    'go/blog" rel="external" target="_blank">Visit our blog</a></li>';
  html += '         <li><span class="divider"></span></li>';
  html += '         <li><a href="#" fn="NIRV.logout">Logout</a></li>';
  html += '      </ul>';
  html += '   </li>';
  html += '</ul>';
  return html;
};

NIRV.gettingStarted = function () {
  NIRV.highlight_tasks(false);

  var html = '';

  html += '<div id="gettingstarted">';
  html +=
    "   <div style='position: absolute; top: 18px; right: 12px; font-size: 11px; color:rgba(127,127,127,0.5);'>Nirvana Build: " +
    NIRV.version +
    '</div>';
  html += '   <h2>Hello!</h2>';
  html += '   <p>A few points to get you oriented:</p>';
  html += '   <ul>';
  html +=
    '     <li>The left navi contains all of your core GTD lists — Inbox, Next, Waiting and so-on</li>';
  html +=
    '     <li>You can move items between lists by dragging-and-dropping, or by right-clicking for options</li>';
  html +=
    '     <li>You can manually sort your actions and projects by dragging them up / down within lists</li>';
  html +=
    "     <li>Nirvana automatically 'stars' items as scheduled and due dates arrive</li>";
  html +=
    "     <li>The Focus list contains items that have been 'starred'</li>";
  html += '   </ul>';
  html += '   <h2>Email to Inbox</h2>';
  html +=
    '   <p>You can also add items to your Nirvana Inbox via email.<br />Your personal inbox address is <a rel="external" href="mailto:Nirvana%20Inbox%3C' +
    NIRV.user.inmailaddress +
    '@nirvanahq.in%3E?subject=New%20Item%20via%20Email&body=Save%20this%20address%20to%20your%20Address%20Book%20so%20you%20can%20easly%20email%20tasks%20to%20Nirvana%20in%20the%20future.%0A%0ATIPS%3A%0A%0A-%20The%20email%20subject%20becomes%20the%20task%20name.%0A-%20Any%20notes%20you%27d%20like%20to%20attach%20to%20your%20task%20should%20go%20in%20the%20email%20body.%0A-%20Email%20must%20be%20less%20than%20100%20kb.%20%20Attachments%20are%20stripped.%0A%0AGive%20it%20a%20try...%20Click%20Send%20to%20post%20a%20new%20task%20to%20your%20Nirvana%20Inbox%20with%20the%20contents%20of%20this%20email.">' +
    NIRV.user.inmailaddress +
    '@nirvanahq.in</a></p>';
  html += '   <h2>Go Deeper</h2>';
  html +=
    "   <p>Take a few minutes to read our <a href='" +
    NIRV.baseurl_www +
    "go/getting-started' rel='external' target='_blank'>Quick Guide</a> for a step-by-step walkthrough of Getting Things Done® with Nirvana.  We would also love if you followed us on <a href='https://medium.com/nirvanahq' rel='external' target='_blank'>Medium</a>, <a href='https://twitter.com/nirvanahq' rel='external' target='_blank'>Twitter</a> or <a href='https://facebook.com/nirvanahq' rel='external' target='_blank'>Facebook</a> for app updates, Nirvana news and nuggets of GTD® wisdom.</p>";
  html += '</div>';

  var options = {
    autoOpen: false,
    closeOnEscape: true,
    closeText: 'Done',
    dialogClass: 'gettingstarted',
    title: 'Getting Started ',
    width: 720,
    position: NIRV.dialog_position_default,
    modal: true,
    resizable: false,
    buttons: {
      Done: function () {
        $(this).dialog('close');
      },
    },
  };
  // $('#dialog').dialog('destroy');
  $('#dialog').html(html);
  $('#dialog').dialog(options);
  $('#dialog').dialog('open');
};

NIRV.keyboardShortcuts = function () {
  NIRV.highlight_tasks(false);

  var html = '';

  html += '<div id="keyboardshortcuts">';
  html += '  <table>';
  html += "    <tr valign='top'>";
  html += "      <td style='padding-right: 25px;'>";

  html += '        <ul>';
  html += "          <li class='label'><h4>Create a New ...</h4></li>";
  html +=
    "          <li><h5>Item in current list</h5> <div class='keys'><i>n</i></div></li>";
  html +=
    "          <li><h5>Item in current list, at top</h5> <div class='keys'><i>shift</i><i>n</i></div></li>";
  html += "          <li class='label'></li>";
  html +=
    "          <li><h5>Action in Inbox</h5> <div class='keys'><i>i</i></div></li>";
  html += "          <li class='label'></li>";
  html +=
    "          <li><h5>Action in Next</h5> <div class='keys'><i>x</i></div></li>";
  html +=
    "          <li><h5>Action in Waiting</h5> <div class='keys'><i>w</i></div></li>";
  html +=
    "          <li><h5>Action in Scheduled</h5> <div class='keys'><i>s</i></div></li>";
  html +=
    "          <li><h5>Action in Someday</h5> <div class='keys'><i>y</i></div></li>";
  html += "          <li class='label'></li>";
  html +=
    "          <li><h5>Action in Focus</h5> <div class='keys'><i>f</i></div></li>";
  html += "          <li class='label'></li>";
  html +=
    "          <li><h5>Project</h5> <div class='keys'><i>p</i></div></li>";
  html +=
    "          <li><h5>Reference List</h5> <div class='keys'><i>l</i></div></li>";
  html += '        </ul>';

  html += '        <ul>';
  html += "          <li class='label'><h4>Navigation</h4></li>";
  html +=
    "          <li><h5>Inbox through Trash</h5> <div class='keys'><i>1</i> ... <i>9</i>, <i>0</i></div></li>";
  // html += "          <li><h5>Traverse Up, Down</h5> <div class='keys'><i>&uarr;</i>, <i>&darr;</i></div></li>";
  html +=
    "          <li><h5>Traverse Up, Down</h5> <div class='keys'><i>[</i>, <i>]</i></div></li>";
  html += '        </ul>';

  html += '      </td>';
  html += '      <td>';

  html += '        <ul>';
  html += "          <li class='label'><h4>Filtering by Area</h4></li>";
  html +=
    "          <li><h5>All Areas</h5> <div class='keys'><i>shift</i><i>0</i></div></li>";
  // html += "          <li><h5>Next Area</h5> <div class='keys'><i>shift</i><i>&rarr;</i></div></li>";
  html +=
    "          <li><h5>Next Area</h5> <div class='keys'><i>shift</i><i>]</i></div></li>";
  // html += "          <li><h5>Previous Area</h5> <div class='keys'><i>shift</i><i>&larr;</i></div></li>";
  html +=
    "          <li><h5>Previous Area</h5> <div class='keys'><i>shift</i><i>[</i></div></li>";
  html +=
    "          <li><h5>Unassigned</h5> <div class='keys'><i>shift</i><i>9</i></div></li>";
  html += '        </ul>';

  html += '        <ul>';
  html += "          <li class='label'><h4>More Goodies</h4></li>";
  html += "          <li><h5>Search</h5> <div class='keys'><i>/</i></div></li>";
  html +=
    "          <li><h5>Cleanup</h5> <div class='keys'><i>c</i></div></li>";
  html +=
    "          <li><h5>Refresh</h5> <div class='keys'><i>r</i></div></li>";
  html +=
    "          <li><h5>Manage Tags</h5> <div class='keys'><i>g</i></div></li>";
  html +=
    "          <li><h5>App Preferences</h5> <div class='keys'><i>,</i></div></li>";
  html +=
    "          <li><h5>Close Window / Cancel</h5> <div class='keys'><i>esc</i></div></li>";
  html += '        </ul>';

  html += '        <ul>';
  html += "          <li class='label'><h4>Help</h4></li>";
  html +=
    "          <li><h5>Getting Started</h5> <div class='keys'><i>h</i></div></li>";
  html +=
    "          <li><h5>Keyboard Shortcuts (this screen)</h5> <div class='keys'><i>k</i></div></li>";
  html += '        </ul>';

  html += '      </td>';
  html += '    </tr>';
  html += '  </table>';
  html += '</div>';

  var options = {
    autoOpen: false,
    closeOnEscape: true,
    closeText: 'Done',
    dialogClass: 'gettingstarted',
    title: 'Keyboard Shortcuts',
    height: 'auto',
    width: 'auto',
    position: NIRV.dialog_position_default,
    modal: true,
    resizable: false,
    buttons: {
      Done: function () {
        $(this).dialog('close');
      },
    },
  };
  // $('#dialog').dialog('destroy');
  $('#dialog').html(html);
  $('#dialog').dialog(options);
  $('#dialog').dialog('open');
};

NIRV.toggleSidebarHelp = function () {
  if ($('#help:visible').length == 1) {
    NIRV.setPref('UIBShowSidebarHelp', 0, true);
    $('#help').fadeOut(100);
  } else {
    NIRV.setPref('UIBShowSidebarHelp', 1, true);
    $('#help').fadeIn(100);
  }
};

NIRV.showDiagnostics = function () {
  NIRV.highlight_tasks(false);

  var diagnostics = NIRV.diagnostics();

  var html = '';
  html += '<div class="content">';
  html += '   <textarea>\n\n*Diagnostics/Snapshot*\n\n';

  for (var i in diagnostics) {
    html += i.toUpperCase() + ' ' + diagnostics[i] + '\n';
  }
  html += '\n\n';

  html += '--------------------------------------------------------------\n\n';

  // STALE TASKS
  for (var i in NIRV.tasks) {
    if (NIRV.tasks[i] != undefined && NIRV.tasks[i].__stale__) {
      for (var s in NIRV.tasks[i]) {
        if (s[0] == '_' && s[1] != '_' && NIRV.tasks[i][s] > NIRV.since) {
          html += i + ' ' + NIRV.tasks[i][s] + ' ' + s + '\n';
        }
      }
    }
  }

  // STALE TAGS
  for (var i in NIRV.tags) {
    if (NIRV.tags[i].__stale__) {
      for (var s in NIRV.tags[i]) {
        if (s[0] == '_' && s[1] != '_' && NIRV.tags[i][s] > NIRV.since) {
          html += md5(i) + ' ' + NIRV.tags[i][s] + ' ' + s + '\n';
        }
      }
    }
  }

  // STALE PREFS
  for (var i in NIRV.prefs) {
    if (NIRV.prefs[i].__stale__) {
      for (var s in NIRV.prefs[i]) {
        if (s[0] == '_' && s[1] != '_' && NIRV.prefs[i][s] > NIRV.since) {
          html += i + ' ' + NIRV.prefs[i][s] + ' ' + s + '\n';
        }
      }
    }
  }

  html += '\n';

  // ILLEGAL TAGS
  for (var i in NIRV.tags) {
    if (i.indexOf('&amp;') != -1) {
      html +=
        'AFFECTED BY AMPERSAND BUG ' + NIRV.tags[i].type + ' ' + md5(i) + '\n';
    }
    if (i.indexOf('"') != -1) {
      html +=
        'AFFECTED BY DBLQUOTES BUG ' + NIRV.tags[i].type + ' ' + md5(i) + '\n';
    }
  }

  // ILLEGAL TAGS AFFECTING TASKS
  for (var i in NIRV.tasks) {
    if (
      NIRV.tasks[i] != undefined &&
      NIRV.tasks[i].tags.indexOf('&amp;') != -1
    ) {
      html += 'AFFECTED BY AMPERSAND BUG ' + i + '\n';
    }
    if (NIRV.tasks[i] != undefined && NIRV.tasks[i].tags.indexOf('"') != -1) {
      html += 'AFFECTED BY DBLQUOTES BUG ' + i + '\n';
    }
  }

  html += '\n';
  html += '\n';

  html += '   </textarea>';
  html += '</div>';

  var options = {
    autoOpen: false,
    dialogClass: 'diagnostics',
    title: 'N2 Current State Snapshot',
    height: 'auto',
    width: 640,
    modal: true,
    resizable: false,
    buttons: {
      'Send to Nirvana Dev Team': function () {
        location.href =
          'mailto:devteam@nirvanahq.com?subject=' +
          escape('Problem Report - Diagnostics') +
          '&body=' +
          escape(
            '\n\n\n--------------------------------------------------------------\nPlease describe the nature of the problem above\n\n' +
              $('#dialog textarea').val()
          );
        $(this).dialog('close');
      },
      Cancel: function () {
        $(this).dialog('close');
      },
    },
  };
  // $('#dialog').dialog('destroy');
  $('#dialog').html(html);
  $('#dialog').dialog(options);
  $('#dialog').dialog('open');
};
