// --- NIRV COMMON METHODS ---

// APP INFO FOR API CALLS
NIRV.app_info = function () {
  var app_info = {};
  if (NIRV.isElectron) {
    app_info.version = NIRV.version;
    app_info.id = 'com.nirvanahq.desktop';
  } else {
    app_info.version = NIRV.version;
    app_info.id = 'com.nirvanahq.focus';
  }

  return app_info;
};

// LOGOUT
NIRV.logout = function (p1) {
  DEBUG && console.log('NIRV.logout ' + p1);
  var forced_logout = p1 == 'force';

  var unsaved = false;
  for (var i in NIRV.tags) {
    if (NIRV.tags[i] != undefined && NIRV.tags[i].__stale__) {
      unsaved = true;
    }
  }
  for (var i in NIRV.tasks) {
    if (NIRV.tasks[i] != undefined && NIRV.tasks[i].__stale__) {
      unsaved = true;
    }
  }

  let _logout = async function () {
    clearInterval(NIRV.infiniteloop);

    var authtoken = NIRV.authtoken;

    var u = NIRV.storage.getItem('u');
    NIRV.storage.clear();
    NIRV.storage.setItem('u', u);

    if (!forced_logout) {
      let url =
        `${NIRV.baseurl_nextapi}/auth/destroy?` +
        `&authtoken=${authtoken}` +
        `&appid=${NIRV.app_info().id}` +
        `&appversion=${NIRV.app_info().version}`;
      let body = JSON.stringify();
      let response;
      try {
        // no need to await fetch... we're logging out regardless of api response
        response = fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: body,
        });
      } catch (error) {
        // console.log('Error:', error);
      }
    }

    $('.onemomentplease').remove();
    setTimeout(function () {
      NIRV.reset();
      NIRV.goLogin();
    }, 500);
  };

  if (forced_logout) {
    _logout(); // we just don't care
  } else if (!unsaved) {
    _logout(); // everything's good
  } else {
    // we're about to lose unsaved changes
    NIRV.confirm(
      'Are you sure you want to logout? <br />You have unsynced items. These changes will be lost.',
      () => {
        _logout();
      }
    );
  }
};

NIRV.setDefaults = function () {
  DEBUG && console.log('NIRV.setDefaults()');

  // ----- LOCALISATION | COMMON -----

  NIRV.prefs.UILanguage = NIRV.Pref.clone({
    key: 'UILanguage',
    value: 'en',
    _value: '0',
  });

  NIRV.prefs.UIDateLocale = NIRV.Pref.clone({
    key: 'UIDateLocale',
    value: 'en-US',
    _value: '0',
  });

  NIRV.prefs.UIDateWeekStartsOn = NIRV.Pref.clone({
    key: 'UIDateWeekStartsOn',
    value: '0',
    _value: '0',
  });

  NIRV.prefs.UIRelativeDueDates = NIRV.Pref.clone({
    key: 'UIRelativeDueDates',
    value: '1',
    _value: '0',
  });

  // ----- APPEARANCE | DESKTOP -----

  NIRV.prefs.UIBAppearance = NIRV.Pref.clone({
    key: 'UIBAppearance',
    value: 'auto', // dark | light | auto
    _value: '0',
  });

  NIRV.prefs.UIBDensity = NIRV.Pref.clone({
    key: 'UIBDensity',
    value: 'compact',
    _value: '0',
  });

  NIRV.prefs.UIB3FontFamily = NIRV.Pref.clone({
    key: 'UIB3FontFamily',
    value: 'proxima-nova',
    _value: '0',
  });

  NIRV.prefs.UIBTheme = NIRV.Pref.clone({
    key: 'UIBTheme',
    value: 'default',
    _value: '0',
  });

  NIRV.prefs.UIBThemeBackground = NIRV.Pref.clone({
    key: 'UIBThemeBackground',
    value: 'light',
    _value: '0',
  });

  NIRV.prefs.UIBThemeName = NIRV.Pref.clone({
    key: 'UIBThemeName',
    value: 'blue',
    _value: '0',
  });

  // ---- WORKFLOW | COMMON -----

  NIRV.prefs.UIVersion = NIRV.Pref.clone({
    key: 'UIVersion',
    value: '', // 2023
    _value: '0',
  });

  NIRV.prefs.UIAreaAssignment = NIRV.Pref.clone({
    key: 'UIAreaAssignment',
    value: '0',
    _value: '0',
  });

  NIRV.prefs.UIAreaFiltering = NIRV.Pref.clone({
    key: 'UIAreaFiltering',
    value: 'exclusive',
    _value: '0',
  });

  NIRV.prefs.UICollectCompleted = NIRV.Pref.clone({
    key: 'UICollectCompleted',
    value: 'daily', // immediately | manually | daily
    _value: '0',
  });

  NIRV.prefs.UIBLeaveCompletedInPlace = NIRV.Pref.clone({
    key: 'UIBLeaveCompletedInPlace',
    value: '0',
    _value: '0',
  });

  NIRV.prefs.UIDefaultProjectType = NIRV.Pref.clone({
    key: 'UIDefaultProjectType',
    value: '0',
    _value: '0',
  });

  // ----- VIEW OPTIONS | COMMON -----

  NIRV.prefs.UIBFocusGroupBy = NIRV.Pref.clone({
    key: 'UIBFocusGroupBy',
    // value: 'state',
    value: '',
    _value: '0',
  });

  NIRV.prefs.UIBNextGroupBy = NIRV.Pref.clone({
    key: 'UIBNextGroupBy',
    value: 'parentid',
    _value: '0',
  });

  NIRV.prefs.UISortProjects = NIRV.Pref.clone({
    key: 'UISortProjects',
    value: 'manually',
    _value: '0',
  });

  NIRV.prefs.UISortReflists = NIRV.Pref.clone({
    key: 'UISortReflists',
    value: 'manually',
    _value: '0',
  });

  // ----- VIEW OPTIONS | DESKTOP -----

  NIRV.prefs.UIBProjectNextActionsTopN = NIRV.Pref.clone({
    key: 'UIBProjectNextActionsTopN',
    value: '9999',
    _value: '0',
  });

  NIRV.prefs.UIBTaskDetails = NIRV.Pref.clone({
    key: 'UIBTaskDetails',
    value: '1',
    _value: '0',
  });

  NIRV.prefs.UIEnableRapidEntry = NIRV.Pref.clone({
    key: 'UIEnableRapidEntry',
    value: '0',
    _value: '0',
  }); // WAT (inverted boolean)

  // ----- SIDEBAR | COMMON -----

  NIRV.prefs.UICounts = NIRV.Pref.clone({
    key: 'UICounts',
    value: '',
    _value: '0',
  });

  NIRV.prefs.UIEnableLater = NIRV.Pref.clone({
    key: 'UIEnableLater',
    value: '0',
    _value: '0',
  });

  // ----- SIDEBAR | DESKTOP -----

  NIRV.prefs.UIBEastWidth = NIRV.Pref.clone({
    key: 'UIBEastWidth',
    value: '240',
    _value: '0',
  });

  NIRV.prefs.UIBShowSidebarHelp = NIRV.Pref.clone({
    key: 'UIBShowSidebarHelp',
    value: '0',
    _value: '0',
  });

  NIRV.prefs.UIBCollapseCollect = NIRV.Pref.clone({
    key: 'UIBCollapseCollect',
    value: '0',
    _value: '0',
  });

  NIRV.prefs.UIBCollapseActions = NIRV.Pref.clone({
    key: 'UIBCollapseActions',
    value: '0',
    _value: '0',
  });

  NIRV.prefs.UIBCollapseProjects = NIRV.Pref.clone({
    key: 'UIBCollapseProjects',
    value: '0',
    _value: '0',
  });

  NIRV.prefs.UIBCollapseReference = NIRV.Pref.clone({
    key: 'UIBCollapseReference',
    value: '0',
    _value: '0',
  });

  NIRV.prefs.UIBCollapseContexts = NIRV.Pref.clone({
    key: 'UIBCollapseContexts',
    value: '1',
    _value: '0',
  });

  NIRV.prefs.UIBCollapseCleanup = NIRV.Pref.clone({
    key: 'UIBCollapseCleanup',
    value: '0',
    _value: '0',
  });

  // ----- UNUSED / DEPRECATED  -----

  // NIRV.prefs.UIProjectDueCounts = NIRV.Pref.clone({
  //   key: 'UIProjectDueCounts',
  //   value: '',
  //   _value: '0',
  // });

  // NIRV.prefs.UIDateShowWeekNumber = NIRV.Pref.clone({
  //   key: 'UIDateShowWeekNumber',
  //   value: '0',
  //   _value: '0',
  // });
};

NIRV.rehydrateCachedData = function () {
  DEBUG && console.log('NIRV.rehydrateCachedData()');
  if (localStorage.length != 0) {
    var usercount = 0;
    var prefcount = 0;
    var appendcount = 0;
    var taskcount = 0;
    var tagcount = 0;
    var value = '';
    var key = '';
    for (var i = 0; i < localStorage.length; i++) {
      key = NIRV.storage.key(i);
      // USER
      if (key == 'user') {
        value = NIRV.storage.getItem(key);
        if (value != 'undefined') {
          NIRV.user = JSON.parse(value);
          usercount++;
        }
      }
      // TASK
      if (key.slice(0, 5) == 'task.') {
        value = NIRV.storage.getItem(key);
        if (value != 'undefined') {
          var task = JSON.parse(value);
          NIRV.tasks[task.id] = NIRV.Task.clone(task);
          taskcount++;
        }
      }
      // TAG
      else if (key.slice(0, 4) == 'tag.') {
        value = NIRV.storage.getItem(key);
        if (value != 'undefined') {
          var tag = JSON.parse(value);
          if (tag.key != '') {
            NIRV.tags[tag.key] = NIRV.Tag.clone(tag);
            tagcount++;
          }
        }
      }
      // PREF
      else if (key.slice(0, 5) == 'pref.') {
        value = NIRV.storage.getItem(key);
        if (value != 'undefined') {
          var pref = JSON.parse(value);
          if (pref.key != '') {
            // if (NIRV.prefs[pref.key]) {
            //   DEBUG && console.log(
            //     'Default pref: ' + pref.key + ' = ' + NIRV.prefs[pref.key].value
            //   );
            // }
            // DEBUG && console.log('Loaded  pref: ' + pref.key + ' = ' + pref.value);
            NIRV.prefs[pref.key] = NIRV.Pref.clone(pref);
            prefcount++;
          }
        }
      }
      // APPEND
      if (key.slice(0, 7) == 'append.') {
        value = NIRV.storage.getItem(key);
        if (value != 'undefined') {
          var append = JSON.parse(value);
          NIRV.appends[append.id] = NIRV.Append.clone(append);
          appendcount++;
        }
      }
    }
  }

  DEBUG && VERBOSE && console.log(' prefcount: ' + prefcount);
  DEBUG && VERBOSE && console.log(' appendcount: ' + appendcount);
  DEBUG && VERBOSE && console.log(' taskcount: ' + taskcount);
  DEBUG && VERBOSE && console.log(' tagcount: ' + tagcount);
  DEBUG && VERBOSE && console.log(' usercount: ' + usercount);
  DEBUG && VERBOSE && console.log(' ...loaded from NIRV.storage');

  NIRV.currentarea =
    NIRV.storage.getItem('NIRV.currentarea') == null
      ? NIRV.currentarea
      : NIRV.storage.getItem('NIRV.currentarea');
  NIRV.currentview =
    NIRV.storage.getItem('NIRV.currentview') == null
      ? NIRV.currentview
      : NIRV.storage.getItem('NIRV.currentview');
};

NIRV.showSplashSkeleton = function () {
  DEBUG && console.log('NIRV.showSplashSkeleton()');
  $('#content').after(
    '<div class="onemomentplease"><div class="spinner" /></div>'
  );
  $('#content').hide();
};

NIRV.hideSplashSkeleton = function () {
  DEBUG && console.log('NIRV.hideSplashSkeleton()');
  $('.onemomentplease').remove();
  $('#content').show();
};

NIRV.editing = function () {
  var isEditing = false;

  $('form.form_taskedit').each(function () {
    if ($(this).is(':visible')) {
      isEditing = true;
    }
  });

  $('input[type=text]').each(function () {
    if ($(this).is(':focus') && !$(this).hasClass('q')) {
      isEditing = true;
    }
  });

  $('textarea').each(function () {
    if ($(this).is(':focus')) {
      isEditing = true;
    }
  });

  if (NIRV.mouseIsDown) {
    isEditing = true;
  }

  DEBUG && console.log('NIRV.editing', isEditing);
  return isEditing;
};

NIRV.locationrefresh = function () {
  location.href = location.href;
};

NIRV.apiurlbuilder = function (params) {
  params = params || {};
  var common = {
    requestid: NIRV.uuid(),
    clienttime: time(),
    authtoken: NIRV.authtoken,
    appid: NIRV.app_info().id,
    appversion: NIRV.app_info().version,
  };
  for (var i in common) {
    params[i] = common[i];
  }
  return urlbuilder(NIRV.baseurl_api, params);
};

// GENERATE A NEW UUID
NIRV.uuid = function () {
  return uuid4();
};

// CONVENIENCE METHOD FOR SETTING PREFS
// if third param is true, we apply the pref now... meaning that we do
// something with the pref, rather than just saving the updated value
NIRV.setPref = function (key, value, applynow) {
  DEBUG && console.log('NIRV.setPref', key, value, applynow);

  applynow = applynow || false;
  now = time();

  if (NIRV.prefs[key] == undefined) {
    NIRV.prefs[key] = NIRV.Pref.clone({
      key: key,
      value: value,
      __stale__: true,
    });
  }

  NIRV.prefs[key].set('value', value);
  NIRV.prefs[key].save();

  if (applynow == true || applynow == 'true') {
    switch (key) {
      case 'UIBAppearance':
      case 'UIBDensity':
      case 'UIB3FontFamily':
      case 'UIBTheme':
      case 'UIBThemeName':
      case 'UIBThemeBackground':
        NIRV.applyTheme();
        break;

      case 'UIBEastWidth':
        NIRV.reflow();
        break;

      case 'UIBTaskDetails':
        var viewtoggle = value == '3' ? 'expand' : 'collapse';
        for (var i in NIRV.tasks) {
          if (NIRV.tasks[i] != undefined) {
            NIRV.tasks[i].__viewtoggle__ = viewtoggle;
          }
        }
        NIRV.refreshCurrentview();
        break;

      case 'UICollectCompleted':
        if (value == 'immediately') {
          NIRV.collectCompleted();
        }
        break;

      case 'UILanguage':
        NIRV.L = NIRV.langtokens[value];
        NIRV.refreshCurrentview();
        break;

      default:
        NIRV.refreshCurrentview();
    }
  }
};

// CLEANUP DOUBLE-QUOTES
NIRV.purgeeviltags = function () {
  for (var i in NIRV.tags) {
    // we are relying on a side-effect of renameTag() to do our dirty work.
    // the first param "oldkey" matches existing tag.key verbatim.
    // the second param "newkey" is always cleansed before substitution.
    // if oldkey === newkey then renameTag() does nothing.
    if (NIRV.tags[i] != undefined) {
      NIRV.renameTag(NIRV.tags[i].key, NIRV.tags[i].key);
    }
  }
  // for (var i in NIRV.tasks) {
  //   if (NIRV.tasks[i] != undefined) {
  //     NIRV.tasks[i].set('tags', NIRV.tasks[i].tags);
  //   }
  // }
};

// SERVICE LEVEL CHECK
NIRV.promptUpgradeRequired = function (what) {
  if (what == 'projects') {
    setTimeout(function () {
      NIRV.confirm(
        'Please upgrade to Nirvana Pro to create additional projects!',
        () => {
          NIRV.gotoAccount();
        }
      );
    }, 50);
    return true;
  } else if (what == 'reflists') {
    setTimeout(function () {
      NIRV.confirm(
        'Please upgrade to Nirvana Pro to create additional reference lists!',
        () => {
          NIRV.gotoAccount();
        }
      );
    }, 50);
    return true;
  } else if (what == 'areas') {
    setTimeout(function () {
      NIRV.confirm(
        'Please upgrade to Nirvana Pro to create additional areas!',
        () => {
          NIRV.gotoAccount();
        }
      );
    }, 50);
    return true;
  } else if (what == 'recurring') {
    setTimeout(function () {
      NIRV.alert('Please upgrade to Nirvana Pro to create repeating actions!');
    }, 50);
    return true;
  } else {
    setTimeout(function () {
      NIRV.confirm('Please upgrade to Nirvana Pro!', () => {
        NIRV.gotoAccount();
      });
    }, 50);
    return true;
  }
};

// REDIRECT TO ACCOUNT SETTINGS WITH SSO LOGIN
NIRV.gotoAccount = function (landing) {
  if (NIRV.isElectron) {
    window.open(NIRV.baseurl_account + 'login', '_blank');
  } else {
    landing = landing || 'dashboard';
    $('#sso_form').attr('action', NIRV.baseurl_account + 'login');
    $('#sso_form input[name=authtoken]').val(NIRV.authtoken);
    $('#sso_form input[name=go]').val(landing);
    $('#sso_form').submit();
  }
};

// CORRECT OR PURGE GARBAGE DATA
NIRV.underp = function () {
  // return;
  DEBUG && console.log(' NIRV.underp()');

  var _time = time();

  // DELETE ORPHANED TASKS
  var projects = NIRV.projects();
  var pids = [];
  for (var i in projects) {
    if (projects[i].id) {
      pids.push(projects[i].id);
    }
  }
  for (var i in NIRV.tasks) {
    if (
      NIRV.tasks[i] != undefined &&
      NIRV.tasks[i].type == 0 &&
      NIRV.tasks[i].parentid != ''
    ) {
      if (pids.indexOf(NIRV.tasks[i].parentid) === -1) {
        DEBUG &&
          VERBOSE &&
          console.log(
            ' orphaned task',
            NIRV.tasks[i].parentid,
            NIRV.tasks[i].name
          );
        delete NIRV.tasks[i];
        // NIRV.tasks[i].set('deleted', _time, _time);
      }
    }
  }

  // DELETE ORPHANED REFITEMS
  var reflists = NIRV.reflists();
  var rids = [];
  for (var i in reflists) {
    if (reflists[i].id) {
      rids.push(reflists[i].id);
    }
  }
  for (var i in NIRV.tasks) {
    if (
      NIRV.tasks[i] != undefined &&
      NIRV.tasks[i].type == 10 &&
      NIRV.tasks[i].parentid != ''
    ) {
      if (rids.indexOf(NIRV.tasks[i].parentid) === -1) {
        DEBUG &&
          VERBOSE &&
          console.log(
            ' orphaned refitem',
            NIRV.tasks[i].parentid,
            NIRV.tasks[i].name
          );
        delete NIRV.tasks[i];
        // NIRV.tasks[i].set('deleted', _time, _time);
      }
    }
  }
  // DEBUG && console.log(' NIRV.underp : complete');
};

// PROCESS START DATE + DUE DATE RELATED TASK MOVEMENT
NIRV.processStartdatesAndDuedates = function () {
  // return;
  DEBUG && console.log('  NIRV.processStartdatesAndDuedates : invoked');

  var _time = time();
  var _today = today();
  var _tomorrow = new Date().clearTime().addDays(1).toString('yyyyMMdd');
  var counttasksupdated = 0;

  for (var i in NIRV.tasks) {
    if (
      NIRV.tasks[i] != undefined &&
      NIRV.tasks[i].state != NIRV.CONST.LOGGED &&
      NIRV.tasks[i].state != NIRV.CONST.TRASHED
    ) {
      // START DATE and/or DUE DATE
      if (NIRV.tasks[i].state != NIRV.CONST.RECURRING) {
        if (
          NIRV.tasks[i].startdate != '' &&
          NIRV.tasks[i].startdate < _tomorrow
        ) {
          NIRV.tasks[i].set('state', NIRV.CONST.NEXT);
          NIRV.tasks[i].set('startdate', '');
          if (NIRV.tasks[i].seqt == 0) {
            NIRV.tasks[i].set('seqt', _time, _time);
          }
          counttasksupdated++;
        }
        // DUE
        if (
          NIRV.tasks[i].duedate != '' &&
          NIRV.tasks[i].duedate < _tomorrow &&
          NIRV.tasks[i].seqt == 0
        ) {
          NIRV.tasks[i].set('seqt', _time, _time);
          counttasksupdated++;
        }
      }
    }
  }

  return counttasksupdated;
};

// PROCESS RECURRING TASK SPAWNING
NIRV.spawnRecurring = function () {
  DEBUG && console.log('  NIRV.spawnRecurring()');

  var _time = time();
  var _today = today();
  var _tomorrow = new Date().clearTime().addDays(1).toString('yyyyMMdd');
  var counttasksupdated = 0;

  for (var i in NIRV.tasks) {
    if (
      NIRV.tasks[i] != undefined &&
      NIRV.tasks[i].state == NIRV.CONST.RECURRING &&
      NIRV.tasks[i].recurring
    ) {
      var recurring = JSON.parse(NIRV.tasks[i].recurring);
      var nextdates = NIRV.calcnextdates(recurring);
      var uptodate =
        recurring.spawnxdaysbefore == undefined
          ? new Date().clearTime().toString('yyyyMMdd')
          : new Date()
              .clearTime()
              .addDays(recurring.spawnxdaysbefore)
              .toString('yyyyMMdd');

      var spawnFromTask = function (task, nextdate) {
        // WE WILL NOT USE NORMAL TASK SETTERS as we want to explicitly set timestamps to midnight, rather than now
        // var midnight = parseInt(new Date().clearTime().getTime().toString().slice(0,10),10);
        var midnight = yyyymmdd2time(nextdate);
        var spawnid = task.id + '-' + nextdate;
        // DEBUG && console.log('nextdate: ' + nextdate + ' --> '+ midnight);
        NIRV.tasks[spawnid] = NIRV.Task.clone(NIRV.tasks[task.id], midnight);
        NIRV.tasks[spawnid].id = spawnid;
        NIRV.tasks[spawnid].state = NIRV.CONST.NEXT;
        NIRV.tasks[spawnid].startdate = '';
        NIRV.tasks[spawnid].recurring = '';
        NIRV.tasks[spawnid].seq = midnight;
        NIRV.tasks[spawnid].seqt = midnight;
        if (recurring.hasduedate) {
          NIRV.tasks[spawnid].duedate = nextdate;
        }
        // set all timestamps to ONE
        NIRV.tasks[spawnid]._state = 1;
        NIRV.tasks[spawnid]._ps = 1;
        NIRV.tasks[spawnid]._parentid = 1;
        NIRV.tasks[spawnid]._waitingfor = 1;
        NIRV.tasks[spawnid]._completed = 1;
        NIRV.tasks[spawnid]._cancelled = 1;
        NIRV.tasks[spawnid]._seq = 1;
        NIRV.tasks[spawnid]._seqt = 1;
        NIRV.tasks[spawnid]._seqp = 1;
        NIRV.tasks[spawnid]._name = 1;
        NIRV.tasks[spawnid]._tags = 1;
        NIRV.tasks[spawnid]._note = 1;
        NIRV.tasks[spawnid]._etime = 1;
        NIRV.tasks[spawnid]._energy = 1;
        NIRV.tasks[spawnid]._duedate = 1;
        NIRV.tasks[spawnid]._startdate = 1;
        NIRV.tasks[spawnid]._recurring = 1;
        // mark stale for save
        NIRV.tasks[spawnid].__stale__ = true;
        counttasksupdated++;
      };

      for (var n = 0; n < nextdates.length; n++) {
        if (nextdates[n] <= uptodate) {
          var spawnid = NIRV.tasks[i].id + '-' + nextdates[n];
          if (NIRV.tasks[spawnid] == undefined) {
            if (recurring.count && parseInt(recurring.count, 10) > 0) {
              if (!recurring.paused) {
                recurring.count -= 1;
                spawnFromTask(NIRV.tasks[i], nextdates[n]);
              }
            } else if (recurring.until && recurring.until <= _today) {
              if (!recurring.paused) {
                spawnFromTask(NIRV.tasks[i], nextdates[n]);
              }
            } else {
              if (!recurring.paused) {
                spawnFromTask(NIRV.tasks[i], nextdates[n]);
              }
            }
          }
          if (nextdates[n + 1] != undefined) {
            recurring.nextdate = nextdates[n + 1];
          }
        }
      }

      // UPDATE MASTER RECURRING TASK
      if (recurring.count != undefined) {
        if (parseInt(recurring.count, 10) < 1) {
          recurring.count = 0;
          NIRV.tasks[i].set('recurring', '');
          NIRV.tasks[i].set('state', NIRV.CONST.DELETED);
          NIRV.tasks[i].set('deleted', _time);
          counttasksupdated++;
        }
      } else if (recurring.until != undefined) {
        if (recurring.until <= _today) {
          NIRV.tasks[i].set('recurring', '');
          NIRV.tasks[i].set('state', NIRV.CONST.DELETED);
          NIRV.tasks[i].set('deleted', _time);
          counttasksupdated++;
        }
      }

      var JSON_stringify_recurring = JSON.stringify(recurring);
      if (NIRV.tasks[i].recurring != JSON_stringify_recurring) {
        NIRV.tasks[i].set('recurring', JSON_stringify_recurring);
        NIRV.tasks[i].set('startdate', recurring.nextdate); // HACK ... we use this for sorting
      }
    }
  }

  NIRV.save();

  return counttasksupdated;
};

NIRV.update = function (json) {
  DEBUG && console.log('  NIRV.update()', NIRV.update_counter);

  // arrays for incoming objects
  var incoming_errors = [];
  var incoming_users = [];
  var incoming_prefs = [];
  var incoming_tags = [];
  var incoming_tasks = [];
  var incoming_appends = [];

  // convenience & code clarity
  var existing_pref, existing_task, existing_tag, existing_append;

  // extract into categorized arrays
  if (json != undefined && json.results != undefined) {
    for (var j = 0; j < json.results.length; j++) {
      if (json.results[j].error != undefined) {
        incoming_errors.push(json.results[j].error);
      }
      if (json.results[j].user != undefined) {
        incoming_users.push(json.results[j].user);
      }
      if (json.results[j].pref != undefined) {
        incoming_prefs.push(json.results[j].pref);
      }
      if (json.results[j].tag != undefined) {
        incoming_tags.push(json.results[j].tag);
      }
      if (json.results[j].task != undefined) {
        incoming_tasks.push(json.results[j].task);
      }
      if (json.results[j].append != undefined) {
        incoming_appends.push(json.results[j].append);
      }
    }

    // PROCESS ERRORS
    incoming_errors.forEach((incoming_error) => {
      console.log('NIRV.update error', incoming_error);

      // RAISE CONDITION 2
      // INVALID AUTH TOKEN
      if (incoming_error.code == '2') {
        NIRV.logout('force');
        return;
      }

      // RAISE CONDITION 1062
      // DUPLICATE UUID FOR TASK INSERT ATTEMPT (so much for UUIDs being universally unique, eh?)
      if (incoming_error.code == '1062') {
        var parts = incoming_error.message.split("'");
        if (parts[3] == 'UUID') {
          if (NIRV.tasks[parts[1]] != undefined) {
            NIRV.rotateUUIDforTask(NIRV.tasks[parts[1]]);
          }
        }
      }

      // RAISE CONDITION 6
      // MALFORMED UUID
      if (
        incoming_error.code == '6' &&
        incoming_error.message.indexOf('id must be a valid UUID') != -1
      ) {
        var parts = incoming_error.message.split(' ');
        var uuid = parts[parts.length - 1];
        if (NIRV.tasks[uuid] != undefined) {
          NIRV.rotateUUIDforTask(NIRV.tasks[uuid]);
        }
      }
    });

    // PROCESS USER
    incoming_users.forEach((incoming_user) => {
      if (incoming_user && incoming_user.id != undefined) {
        if (NIRV.user && NIRV.user.id != undefined) {
          if (incoming_user.id != NIRV.user.id) {
            NIRV.logout('force');
          }
        }
        NIRV.setUser(incoming_user);
      }
    });

    // PROCESS PREFS
    incoming_prefs.forEach((incoming_pref) => {
      DEBUG &&
        VERBOSE &&
        console.log('   pref ' + incoming_pref.key + ' ' + incoming_pref.value);

      existing_pref = NIRV.prefs[incoming_pref.key];

      if (existing_pref) {
        NIRV.prefs[incoming_pref.key].__stale__ = false;
      }

      // A. incoming is marked for deletion
      if (incoming_pref.deleted > 0) {
        DEBUG &&
          VERBOSE &&
          console.log('    incoming pref is marked for deletion');
        NIRV.storage.removeItem('pref.' + incoming_pref.key); // LS
        delete NIRV.prefs[incoming_pref.key];
      }

      // B. incoming is a new pref
      else if (existing_pref == undefined) {
        // DEBUG && VERBOSE && console.log('    incoming pref is a new pref');
        NIRV.prefs[incoming_pref.key] = NIRV.Pref.clone(incoming_pref);
      }

      // C. incoming must merge with existing pref
      else {
        Object.keys(incoming_pref).forEach((k) => {
          if (existing_pref[k] && k[0] === '_' && k[1] !== '_' && k != '_key') {
            //  1. timestamp comparison indicates value from SERVER wins
            if (parseInt(incoming_pref[k]) > parseInt(existing_pref[k])) {
              DEBUG &&
                // VERBOSE &&
                console.log(
                  '    timestamp indicates v from SERVER wins',
                  k,
                  existing_pref.value,
                  '»',
                  incoming_pref.value
                );
              NIRV.prefs[incoming_pref.key][k] = incoming_pref[k];
              NIRV.prefs[incoming_pref.key][k.substring(1)] =
                incoming_pref[k.substring(1)];
            }

            // 2. timestamp comparison indicates value from CLIENT wins
            else if (parseInt(incoming_pref[k]) < parseInt(existing_pref[k])) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp indicates v from CLIENT wins');
              // NIRV.prefs[incoming_pref.key].__stale__ = true;
            }

            // 3. timestamp same but values different indicate CLIENT wins
            else if (
              parseInt(incoming_pref[k]) == parseInt(existing_pref[k]) &&
              incoming_pref[k.substring(1)] != existing_pref[k.substring(1)]
            ) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp same v diff CLIENT wins');
              NIRV.prefs[incoming_pref.key][k] =
                parseInt(NIRV.prefs[incoming_pref.key][k]) + 1;
              NIRV.prefs[incoming_pref.key].__stale__ = true;
            }

            //
            else {
              // DEBUG && console.log('    timestamps means nothing to me');
            }
          }
        });
      }

      NIRV.storage.setItem(
        'pref.' + incoming_pref.key,
        JSON.stringify(NIRV.prefs[incoming_pref.key])
      ); // LS
    });

    // PROCESS TAGS
    incoming_tags.forEach((incoming_tag) => {
      DEBUG && VERBOSE && console.log('   tag ' + incoming_tag.key);

      existing_tag = NIRV.tags[incoming_tag.key];

      if (existing_tag) {
        NIRV.tags[incoming_tag.key].__stale__ = false;
      }

      // A. incoming is marked for deletion
      if (incoming_tag.deleted > 0) {
        DEBUG &&
          VERBOSE &&
          console.log('    incoming tag is marked for deletion');
        NIRV.storage.removeItem('tag.' + incoming_tag.key); // LS
        delete NIRV.tags[incoming_tag.key];
      }

      // B. incoming is a new tag
      else if (existing_tag == undefined) {
        // DEBUG && VERBOSE && console.log('    incoming tag is a new tag');
        NIRV.tags[incoming_tag.key] = NIRV.Tag.clone(incoming_tag);
      }

      // C. incoming must merge with existing tag
      else {
        Object.keys(incoming_tag).forEach((k) => {
          if (existing_tag[k] && k[0] === '_' && k[1] !== '_' && k !== '_key') {
            //  1. timestamp comparison indicates value from SERVER wins
            if (incoming_tag[k] > existing_tag[k]) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp indicates v from SERVER wins');
              NIRV.tags[incoming_tag.key][k] = incoming_tag[k];
              NIRV.tags[incoming_tag.key][k.substring(1)] =
                incoming_tag[k.substring(1)];
            }

            // 2. timestamp comparison indicates value from CLIENT wins
            else if (incoming_tag[k] < existing_tag[k]) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp indicates v from CLIENT wins');
              // NIRV.tags[incoming_tag.key].__stale__ = true;
            }

            // 3. timestamp same but values different indicate CLIENT wins
            else if (
              incoming_tag[k] == existing_tag[k] &&
              incoming_tag[k.substring(1)] != existing_tag[k.substring(1)]
            ) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp same v different CLIENT wins');
              NIRV.tags[incoming_tag.key][k] =
                parseInt(NIRV.tags[incoming_tag.key][k]) + 1;
              NIRV.tags[incoming_tag.key].__stale__ = true;
            }

            //
            else {
              // DEBUG && VERBOSE && console.log('    timestamps means nothing to me');
            }
          }
        });
      }

      NIRV.storage.setItem(
        'tag.' + incoming_tag.key,
        JSON.stringify(NIRV.tags[incoming_tag.key])
      ); // LS
    });

    // PROCESS TASKS
    incoming_tasks.forEach((incoming_task) => {
      DEBUG &&
        VERBOSE &&
        console.log('   task ' + incoming_task.id + ' ' + incoming_task.name);

      existing_task = NIRV.tasks[incoming_task.id];

      if (existing_task) {
        NIRV.tasks[incoming_task.id].__stale__ = false;
      }

      // A. incoming is marked for deletion
      if (incoming_task.deleted > 0) {
        DEBUG &&
          VERBOSE &&
          console.log('    incoming task is marked for deletion');
        NIRV.storage.removeItem('task.' + incoming_task.id); // LS
        delete NIRV.tasks[incoming_task.id];
      }

      // B. incoming is a new task
      else if (existing_task == undefined) {
        DEBUG && VERBOSE && console.log('    incoming task is a new task');
        NIRV.tasks[incoming_task.id] = NIRV.Task.clone(incoming_task);
      }

      // C. incoming must merge with existing task
      else {
        Object.keys(incoming_task).forEach((k) => {
          if (existing_task[k] && k[0] === '_' && k[1] !== '_') {
            //  1. timestamp comparison indicates value from SERVER wins
            if (incoming_task[k] > existing_task[k]) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp indicates v from SERVER wins');
              NIRV.tasks[incoming_task.id][k] = incoming_task[k];
              NIRV.tasks[incoming_task.id][k.substring(1)] =
                incoming_task[k.substring(1)];
            }

            // 2. timestamp comparison indicates value from CLIENT wins
            else if (incoming_task[k] < existing_task[k]) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp indicates value from CLIENT wins');
              // NIRV.tasks[incoming_task.id].__stale__ = true;
            }

            // 3. timestamp same but values different indicate CLIENT wins
            else if (
              incoming_task[k] == existing_task[k] &&
              incoming_task[k.substring(1)] != existing_task[k.substring(1)]
            ) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp same v different CLIENT wins');
              NIRV.tasks[incoming_task.id][k] =
                parseInt(NIRV.tasks[incoming_task.id][k]) + 1;
              NIRV.tasks[incoming_task.id].__stale__ = true;
            }

            //
            else {
              // DEBUG && VERBOSE && console.log('    timestamps means nothing to me');
            }
          }
        });
      }

      NIRV.storage.setItem(
        'task.' + incoming_task.id,
        JSON.stringify(NIRV.tasks[incoming_task.id])
      ); // LS
    });

    // PROCESS APPENDS
    incoming_appends.forEach((incoming_append) => {
      DEBUG && VERBOSE && console.log('   append ' + incoming_append.id);

      existing_append = NIRV.appends[incoming_append.id];

      if (existing_append) {
        NIRV.appends[incoming_append.id].__stale__ = false;
      }

      // A. incoming is marked for deletion
      if (incoming_append.deleted > 0) {
        DEBUG &&
          VERBOSE &&
          console.log('    incoming append is marked for deletion');
        NIRV.storage.removeItem('append.' + incoming_append.id); // LS
        delete NIRV.appends[incoming_append.id];
      }

      // B. incoming is a new append
      else if (existing_append == undefined) {
        // DEBUG && VERBOSE && console.log('    incoming append is a new append');
        NIRV.appends[incoming_append.id] = NIRV.Append.clone(incoming_append);
      }

      // C. incoming must merge with existing append
      else {
        Object.keys(incoming_append).forEach((k) => {
          if (existing_append[k] && k[0] === '_' && k[1] !== '_') {
            //  1. timestamp comparison indicates value from SERVER wins
            if (incoming_append[k] > existing_append[k]) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp indicates v from SERVER wins');
              NIRV.appends[incoming_append.id][k] = incoming_append[k];
              NIRV.appends[incoming_append.id][k.substring(1)] =
                incoming_append[k.substring(1)];
            }

            // 2. timestamp comparison indicates value from CLIENT wins
            else if (incoming_append[k] < existing_append[k]) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp indicates v from CLIENT wins');
              // NIRV.appends[incoming_append.id].__stale__ = true;
            }

            // 3. timestamp same but values different indicate CLIENT wins
            else if (
              incoming_append[k] == existing_append[k] &&
              incoming_append[k.substring(1)] != existing_append[k.substring(1)]
            ) {
              DEBUG &&
                VERBOSE &&
                console.log('    timestamp same v different CLIENT wins');
              NIRV.appends[incoming_append.id][k] =
                parseInt(NIRV.appends[incoming_append.id][k]) + 1;
              NIRV.appends[incoming_append.id].__stale__ = true;
            }

            //
            else {
              // DEBUG && VERBOSE && console.log('    timestamps means nothing to me');
            }
          }
        });
      }

      NIRV.storage.setItem(
        'append.' + incoming_append.id,
        JSON.stringify(NIRV.appends[incoming_append.id])
      );
    });
  }

  NIRV.underp();

  if (json.request.return && json.request.return == 'everything') {
    NIRV.setSince(json.request.servertime);
  }

  setTimeout(function () {
    NIRV.update_counter += 1;
    NIRV.nextSyncFromZero = false;
    NIRV.must_sync = false; // stop trying

    NIRV.processNightly();

    if ($('.onemomentplease').length > 0) {
      NIRV.refreshCurrentview();
    } else {
      NIRV.refreshAll();
    }

    NIRV.hideSplashSkeleton();
    NIRV.reflow();
  }, 100);
};

// ROTATE UUID ON TASK (to recover from illegal duplicate UUID insert attempt)
NIRV.rotateUUIDforTask = function (task) {
  DEBUG && console.log('NIRV.rotateUUIDforTask(' + task.id + ')');
  var clone = cloneObject(task);
  clone.id = NIRV.uuid();
  clone.__stale__ = true;
  NIRV.tasks[clone.id] = clone;
  // if project, update parentid on all children
  if (task.type == NIRV.CONST.PROJECT) {
    for (var i in NIRV.tasks) {
      if (NIRV.tasks[i] != undefined && NIRV.tasks[i].parentid == task.id) {
        NIRV.tasks[i].set('parentid', clone.id);
      }
    }
  }
  delete NIRV.tasks[task.id];
  NIRV.storage.removeItem('task.' + task.id);
};

// ROTATE UUID ON APPEND (to recover from illegal duplicate UUID insert attempt)
NIRV.rotateUUIDforAppend = function (append) {
  DEBUG && console.log('NIRV.rotateUUIDforAppend(' + append.id + ')');
  var clone = cloneObject(append);
  clone.id = NIRV.uuid();
  clone.__stale__ = true;
  NIRV.appends[clone.id] = clone;
  delete NIRV.appends[append.id];
  NIRV.storage.removeItem('append.' + append.id);
};

// GATHER UP DIAGNOSTICS DATA FOR USE WHEREVER
NIRV.diagnostics = function () {
  var prefs = 0,
    prefs_pending = 0;
  var tasks = 0,
    tasks_pending = 0;
  var tags = 0,
    tags_pending = 0;

  for (var i in NIRV.prefs) {
    prefs++;
    if (NIRV.prefs[i] != undefined && NIRV.prefs[i].__stale__) {
      prefs_pending++;
    }
  }

  for (var i in NIRV.tasks) {
    tasks++;
    if (NIRV.tasks[i] != undefined && NIRV.tasks[i].__stale__) {
      tasks_pending++;
    }
  }

  for (var i in NIRV.tags) {
    tags++;
    if (NIRV.tags[i] != undefined && NIRV.tags[i].__stale__) {
      tags_pending++;
    }
  }

  var diagnostics = {
    ctime: time(),
    since: NIRV.since,
    user: NIRV.user.username + ' ' + NIRV.user.emailaddress,
    frag: NIRV.authtoken.slice(0, 8),
    build: NIRV.version,
    user_agent: navigator.userAgent,
    prefs: prefs,
    prefs_pending: prefs_pending,
    tasks: tasks,
    tasks_pending: tasks_pending,
    tags: tags,
    tags_pending: tags_pending,
  };

  return diagnostics;
};

// TAG MANAGEMENT
NIRV.addTag = function (tag, queue) {
  DEBUG && console.log('NIRV.addTag()', tag);

  queue = queue || false;
  if (tag.key == undefined) return false;
  if (tag.type == undefined) return false;
  if (tag.key.trim() == '') return false;
  tag.email = tag.email || '';
  if (NIRV.tags[tag.key] == undefined) {
    var now = time();
    NIRV.tags[tag.key] = NIRV.Tag.clone({
      key: tag.key,
      _key: now,
      type: tag.type,
      _type: now,
      email: tag.email,
      _email: now,
      color: tag.color,
      _color: now,
      meta: tag.meta,
      _meta: now,
      deleted: 0,
      _deleted: now,
      __stale__: true,
    });

    NIRV.tags[tag.key].save(queue);
  } else if (NIRV.tags[tag.key].deleted != 0) {
    NIRV.tags[tag.key].set('deleted', 0);
    NIRV.tags[tag.key].save(queue);
  } else {
    return false;
  }
};

NIRV.cleanseTagKey = function (key) {
  key = key.replace(/"/g, ' ');
  key = key.replace(/'/g, ' ');
  key = key.replace(/</g, '');
  key = key.replace(/>/g, '');
  key = key.replace(/\\/g, '');
  // key = key.replace(/,/g,"");
  key = Encoder.htmlDecode(key);
  key = key.trim();
  // key = (key == '') ? '_' : key;
  return key;
};

NIRV.renameTag = function (oldkey, newkey) {
  DEBUG && console.log('NIRV.renameTag()', oldkey, newkey);

  if (oldkey == undefined) return false;
  if (newkey == undefined) return false;
  newkey = NIRV.cleanseTagKey(newkey);
  if (oldkey == newkey) return false;

  var oldtag = NIRV.tags[oldkey];
  if (oldtag == undefined) return false;

  var newtag = {
    key: newkey,
    type: oldtag.type,
    email: oldtag.email,
    color: oldtag.color,
    meta: oldtag.meta,
  };

  NIRV.addTag(newtag);

  var comma_oldkey_comma = ',' + oldkey + ',';
  var comma_newkey_comma = ',' + newkey + ',';

  for (var i in NIRV.tasks) {
    if (
      NIRV.tasks[i] != undefined &&
      NIRV.tasks[i].tags.indexOf(comma_oldkey_comma) != -1
    ) {
      NIRV.tasks[i].set(
        'tags',
        NIRV.tasks[i].tags.replace(comma_oldkey_comma, comma_newkey_comma)
      );
    }
    if (NIRV.tasks[i] != undefined && NIRV.tasks[i].waitingfor == oldkey) {
      NIRV.tasks[i].set(
        'waitingfor',
        NIRV.tasks[i].waitingfor.replace(oldkey, newkey)
      );
    }
  }

  NIRV.deleteTag(oldkey);
};

NIRV.deleteTag = function (tagkey) {
  DEBUG && console.log('NIRV.deleteTag()', tagkey);
  if (tagkey == undefined) return false;
  if (NIRV.tags[tagkey] == undefined) return false;

  var comma_tagkey_comma = ',' + tagkey + ',';

  for (var i in NIRV.tasks) {
    if (
      NIRV.tasks[i] != undefined &&
      NIRV.tasks[i].state != NIRV.CONST.LOGGED
    ) {
      if (NIRV.tasks[i].tags.indexOf(comma_tagkey_comma) != -1) {
        NIRV.tasks[i].set(
          'tags',
          NIRV.tasks[i].tags.replace(comma_tagkey_comma, ',')
        );
      }
      if (NIRV.tasks[i].waitingfor == tagkey) {
        if (NIRV.tags['Someone'] == undefined) {
          NIRV.addTag({
            key: 'Someone',
            type: NIRV.CONST.CONTACT,
          });
        }
        NIRV.tasks[i].set('waitingfor', 'Someone');
      }
    }
  }

  NIRV.tags[tagkey].set('deleted', time());
  NIRV.tags[tagkey].set('email', '');
  NIRV.tags[tagkey].set('meta', '');
  NIRV.recalcWtasks();
  NIRV.refreshMain();
};

// PROCESS TAGS
NIRV.processTags = function (task) {
  // DEBUG && console.log('NIRV.processTags(' + task.tags + ')');
  if (task.state != NIRV.CONST.DELETED && task.state != NIRV.CONST.LOGGED) {
    // -- sanitize
    var stags = task.tags.replace(/"/g, '');
    var atags = stags.split(',');
    for (var i = 0; i < atags.length; i++) {
      if (atags[i] == undefined) return;
      if (atags[i].trim() == '') {
        atags.splice(i, 1);
        i--;
        // because we've just shifted everything forward with the splice
      } else {
        atags[i] = atags[i].trim();
      }
    }
    // -- save new tags
    for (var i = 0; i < atags.length; i++) {
      if (NIRV.tags[atags[i]] == undefined) {
        var tag = {
          key: atags[i],
          type: NIRV.CONST.CONTEXT,
        };
        NIRV.addTag(tag);
      }
    }
    // -- save new contact
    if (task.waitingfor != '') {
      if (NIRV.tags[task.waitingfor] == undefined) {
        var tag = {
          key: task.waitingfor,
          type: NIRV.CONST.CONTACT,
        };
        NIRV.addTag(tag);
      }
    }
  }
};

NIRV.contacts = function () {
  var tags = [];
  for (var i in NIRV.tags) {
    if (NIRV.tags[i].type == NIRV.CONST.CONTACT) {
      tags.push(NIRV.tags[i].key);
    }
  }
  return tags.sort(isort);
};

NIRV.contexts = function () {
  var tags = [];
  for (var i in NIRV.tags) {
    if (NIRV.tags[i].type == NIRV.CONST.CONTEXT) {
      tags.push(NIRV.tags[i].key);
    }
  }
  return tags.sort(isort);
};

NIRV.areas = function () {
  var tags = [];
  for (var i in NIRV.tags) {
    if (NIRV.tags[i].type == NIRV.CONST.AREA && NIRV.tags[i].deleted == 0) {
      tags.push(NIRV.tags[i].key);
    }
  }
  return tags.sort(isort);
};

NIRV.areaCount = function () {
  var count = 0;
  for (var i in NIRV.tags) {
    if (NIRV.tags[i].type == NIRV.CONST.AREA && NIRV.tags[i].deleted == 0) {
      count++;
    }
  }
  return count;
};

NIRV.tagsinuse = function () {
  var task = {};
  var atags = [];
  var ctags = [];
  for (var i in NIRV.tasks) {
    task = NIRV.tasks[i];
    if (task != undefined) {
      atags = task.__tags__().split(',');
      for (var t = 0; t < atags.length; t++) {
        if (atags[t] != '') {
          ctags.push(atags[t]);
        }
      }
      if (task.waitingfor != '') {
        ctags.push(task.waitingfor);
      }
    }
  }
  ctags = ctags.unique();
  ctags.sort(isort);
  return ctags;
};

NIRV.refreshTips = function () {
  DEBUG && VERBOSE && console.log('  NIRV.refreshTips()');

  if ($('#main li.task').length == 0) {
    $('#tips').show();

    // area headsup
    var areaheadsup = '';
    if (NIRV.currentarea == '__ALL__') {
      // skip
    } else if (NIRV.currentarea == '__NONE__') {
      areaheadsup = 'Unassigned';
    } else {
      areaheadsup = '' + NIRV.currentarea + '';
    }

    // SEARCH
    if ($('#north .q:focus').length == 1) {
      if (areaheadsup == '') {
        $('#tips').html(
          "<span class='inner'><h1>No Results for Search</h1>Nirvana looks for matches in item names, notes, and tags.</span>"
        );
      } else {
        $('#tips').html(
          "<span class='inner'><h1>No search results within area &rarr; " +
            areaheadsup +
            '</h1>Nirvana looks for matches in item names, notes, and tags.</span>'
        );
      }
    }
    // PROJECT
    else if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
      $('#tips').html(
        "<span class='inner'><h1>Project</h1>Press <span class='hotkey'>n</span> to create a new action.<span>"
      );
    }
    // REFLIST
    else if (
      NIRV.currentview[0] == 'l' &&
      NIRV.currentview != 'later' &&
      NIRV.currentview != 'logbook'
    ) {
      $('#tips').html(
        "<span class='inner'><h1>Reference</h1>Press <span class='hotkey'>n</span> to create a new reference item.<span>"
      );
    }
    // EVERYTHING ELSE
    else {
      switch (NIRV.currentview) {
        case 'inbox':
          $('#tips').html(
            "<span class='inner'><h1>Your Inbox is empty</h1>Press <span class='hotkey'>n</span> to create a new action.<span>"
          );
          break;

        case 'focus':
          $('#tips').html(
            "<span class='inner'><h1>Your " +
              areaheadsup +
              " Focus list is empty</h1>Press <span class='hotkey'>n</span> to create a new action.</span>"
          );
          break;

        case 'next':
          $('#tips').html(
            "<span class='inner'><h1>Your " +
              areaheadsup +
              " Next Actions list is empty</h1>Press <span class='hotkey'>n</span> to create a new action.</span>"
          );
          break;

        case 'later':
          $('#tips').html(
            "<span class='inner'><h1>Your " +
              areaheadsup +
              " Later list is empty</h1>Press <span class='hotkey'>n</span> to create a new action.</span>"
          );
          break;

        case 'waiting':
          if (areaheadsup == '') {
            $('#tips').html(
              "<span class='inner'><h1>You are not currently Waiting on anyone</h1>Press <span class='hotkey'>n</span> to create a new action.</span>"
            );
          } else {
            $('#tips').html(
              "<span class='inner'><h1>You are not currently Waiting on anyone</h1>Press <span class='hotkey'>n</span> to create a new action.<br />You can also mark existing actions as 'waiting for' by dragging them from other lists on to Waiting.</span>"
            );
          }
          break;

        case 'scheduled':
          if (areaheadsup == '') {
            $('#tips').html(
              "<span class='inner'><h1>You have nothing Scheduled</h1>Press <span class='hotkey'>n</span> to create a new Scheduled action.</span>"
            );
          } else {
            $('#tips').html(
              "<span class='inner'><h1>You have nothing Scheduled</h1>Press <span class='hotkey'>n</span> to create a new Scheduled action.<br />You can also defer tasks and projects for future action by dragging and dropping them on to Scheduled from other lists.</span>"
            );
          }
          break;

        case 'someday':
          $('#tips').html(
            "<span class='inner'><h1>Your " +
              areaheadsup +
              " Someday list is empty</h1>Press <span class='hotkey'>n</span> to create a new action.</span>"
          );
          break;

        case 'projects':
          $('#tips').html(
            "<span class='inner'><h1>No " +
              areaheadsup +
              " Projects to display</h1>Press <span class='hotkey'>n</span> to create a new project.<br />You can also convert existing actions into projects by dragging them on to Projects in the left navi.</span>"
          );
          break;

        case 'reflists':
          $('#tips').html(
            "<span class='inner'><h1>No Reference lists to display</h1>Press <span class='hotkey'>n</span> to create a new reference list.<br />You can also convert existing actions or projects into reference lists by dragging them on to Reference in the left navi.</span>"
          );
          break;

        case 'logbook':
          if (areaheadsup == '') {
            $('#tips').html(
              "<span class='inner'><h1>Nothing to display</h1>No recent activity has been logged</span>"
            );
          } else {
            $('#tips').html(
              "<span class='inner'><h1>Nothing to display</h1>No recent activity has been logged for the selected area &rarr; " +
                areaheadsup +
                '</span>'
            );
          }
          break;

        case 'trash':
          $('#tips').html(
            "<span class='inner'><h1>Trash is empty.</h1>Drag items you wish to delete to the Trash.<br />And don't forget to empty the trash if it starts to fill up!</span>"
          );
          break;
      }
    }
  } else {
    $('#tips').hide();
  }
};

NIRV.updateCounts = function () {
  var _today = today();
  var section = {
    collect: 0,
    actions: 0,
    projects: 0,
    reflists: 0,
  };
  var action = {
    inbox: 0,
    focus: 0,
    next: 0,
    later: 0,
    waiting: 0,
    scheduled: 0,
    someday: 0,
    projects: 0,
    reflists: 0,
  };
  var due = {
    inbox: 0,
    focus: 0,
    next: 0,
    later: 0,
    waiting: 0,
    scheduled: 0,
    someday: 0,
    projects: 0,
  };
  var trash = 0;
  var collect = 0;

  var standalone = {};
  standalone['next'] = 0;

  var areas = NIRV.areas();
  var atags = [];

  var p = {};
  var projects = NIRV.projects();

  for (var i in projects) {
    p[projects[i].id] = {};
    p[projects[i].id]['all'] = 0;
    p[projects[i].id]['next'] = 0;
    p[projects[i].id]['due'] = 0;
    p[projects[i].id]['action'] = 0;
    p[projects[i].id]['completed'] = 0;
  }

  var l = {};
  var reflists = NIRV.reflists();

  for (var i in reflists) {
    l[reflists[i].id] = {};
    l[reflists[i].id]['all'] = 0;
  }

  function plus(task) {
    if (task.__inactive__()) {
      return 0;
    } else if (NIRV.currentarea == '__NONE__') {
      var areafound = false;
      atags = task.__tags__().split(',');
      for (var t = 0; t < atags.length; t++) {
        for (var a = 0; a < areas.length; a++) {
          if (atags.indexOf(areas[a]) != -1) {
            areafound = true;
          }
        }
      }
      return areafound ? 0 : 1;
    } else if (
      NIRV.currentarea != '__ALL__' &&
      task.__tags__().indexOf(',' + NIRV.currentarea + ',') == -1
    ) {
      return 0;
    } else {
      return 1;
    }
  }

  // COUNT
  for (var i in NIRV.tasks) {
    // DEBUG && console.log(i + ' ' + NIRV.tasks[i].deleted);
    if (
      NIRV.tasks[i] != undefined &&
      NIRV.tasks[i].state != NIRV.CONST.DELETED
    ) {
      if (NIRV.tasks[i].state == NIRV.CONST.TRASHED) {
        trash++;
      } else if (
        NIRV.tasks[i].state != NIRV.CONST.LOGGED &&
        NIRV.tasks[i].completed != 0
      ) {
        collect++;
      } else if (NIRV.tasks[i].completed != 0) {
        if (NIRV.tasks[i].parentid != '') {
          if (p[NIRV.tasks[i].parentid] != undefined) {
            p[NIRV.tasks[i].parentid].completed++;
          }
        }
      } else if (NIRV.tasks[i].completed == 0) {
        if (NIRV.tasks[i].state == NIRV.CONST.INBOX) {
          // if (NIRV.tasks[i].duedate != '' && NIRV.tasks[i].duedate <= _today && NIRV.tasks[i].seqt == 0) {
          if (NIRV.tasks[i].duedate != '' && NIRV.tasks[i].duedate <= _today) {
            due.inbox++;
          } else {
            action.inbox++;
          }
        }

        if (NIRV.tasks[i].seqt != 0) {
          if (NIRV.tasks[i].duedate != '' && NIRV.tasks[i].duedate <= _today) {
            due.focus += plus(NIRV.tasks[i]);
          } else {
            action.focus += plus(NIRV.tasks[i]);
          }
        }

        // COLLECT
        section.collect = action.inbox + due.inbox;

        // ACTIONS
        if (
          NIRV.tasks[i].type == NIRV.CONST.TASK &&
          NIRV.tasks[i].state != NIRV.CONST.INBOX
        ) {
          section.actions += plus(NIRV.tasks[i]);
        }

        // PROJECTS
        if (NIRV.tasks[i].type == NIRV.CONST.PROJECT) {
          section.projects += plus(NIRV.tasks[i]);
        }

        // REFERENCE
        if (NIRV.tasks[i].type == NIRV.CONST.REFLIST) {
          section.reflists += plus(NIRV.tasks[i]);
        }

        if (NIRV.tasks[i].type == NIRV.CONST.REFITEM) {
          if (l[NIRV.tasks[i].parentid] != undefined) {
            l[NIRV.tasks[i].parentid].all++;
          }
        }

        // PREF: SHOW ALL COUNTS
        if (NIRV.prefs.UICounts && NIRV.prefs.UICounts.value == 'all') {
          if (NIRV.tasks[i].state == NIRV.CONST.NEXT) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today
            ) {
              due.next += plus(NIRV.tasks[i]);
            } else {
              action.next += plus(NIRV.tasks[i]);
            }
          }
          if (NIRV.tasks[i].state == NIRV.CONST.LATER) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today
            ) {
              due.later += plus(NIRV.tasks[i]);
            } else {
              action.later += plus(NIRV.tasks[i]);
            }
          }
          if (NIRV.tasks[i].state == NIRV.CONST.WAITING) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today
            ) {
              due.waiting += plus(NIRV.tasks[i]);
            } else {
              action.waiting += plus(NIRV.tasks[i]);
            }
          }
          if (
            NIRV.tasks[i].state == NIRV.CONST.SCHEDULED ||
            NIRV.tasks[i].state == NIRV.CONST.RECURRING
          ) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today
            ) {
              due.scheduled += plus(NIRV.tasks[i]);
            } else {
              action.scheduled += plus(NIRV.tasks[i]);
            }
          }
          if (NIRV.tasks[i].state == NIRV.CONST.SOMEDAY) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today
            ) {
              due.someday += plus(NIRV.tasks[i]);
            } else {
              action.someday += plus(NIRV.tasks[i]);
            }
          }
          if (NIRV.tasks[i].type == NIRV.CONST.PROJECT) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today
            ) {
              due.projects += plus(NIRV.tasks[i]);
            } else {
              action.projects += plus(NIRV.tasks[i]);
            }
          }
          if (NIRV.tasks[i].parentid != '') {
            if (p[NIRV.tasks[i].parentid] != undefined) {
              p[NIRV.tasks[i].parentid].all++;
              if (NIRV.tasks[i].state == NIRV.CONST.NEXT) {
                p[NIRV.tasks[i].parentid].next++;
              }
              if (
                NIRV.tasks[i].duedate != '' &&
                NIRV.tasks[i].duedate <= _today
              ) {
                p[NIRV.tasks[i].parentid].due++;
              } else {
                p[NIRV.tasks[i].parentid].action++;
              }
            }
          }
        }

        // PREF: SHOW RELEVANT COUNTS
        else {
          if (
            NIRV.tasks[i].state == NIRV.CONST.NEXT &&
            NIRV.tasks[i].seqt == 0
          ) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today &&
              NIRV.tasks[i].seqt == 0
            ) {
              due.next += plus(NIRV.tasks[i]);
            }
          }
          if (
            NIRV.tasks[i].state == NIRV.CONST.WAITING &&
            NIRV.tasks[i].seqt == 0
          ) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today &&
              NIRV.tasks[i].seqt == 0
            ) {
              due.waiting += plus(NIRV.tasks[i]);
            } else {
              action.waiting += plus(NIRV.tasks[i]);
            }
          }
          if (
            NIRV.tasks[i].state == NIRV.CONST.NEXT &&
            NIRV.tasks[i].parentid == ''
          ) {
            standalone['next']++;
          }
          if (
            (NIRV.tasks[i].state == NIRV.CONST.SCHEDULED ||
              NIRV.tasks[i].state == NIRV.CONST.RECURRING) &&
            NIRV.tasks[i].seqt == 0
          ) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today &&
              NIRV.tasks[i].seqt == 0
            ) {
              due.scheduled += plus(NIRV.tasks[i]);
            }
          }
          if (
            NIRV.tasks[i].state == NIRV.CONST.SOMEDAY &&
            NIRV.tasks[i].seqt == 0
          ) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today &&
              NIRV.tasks[i].seqt == 0
            ) {
              due.someday += plus(NIRV.tasks[i]);
            }
          }
          if (
            NIRV.tasks[i].state == NIRV.CONST.LATER &&
            NIRV.tasks[i].seqt == 0
          ) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today &&
              NIRV.tasks[i].seqt == 0
            ) {
              due.later += plus(NIRV.tasks[i]);
            }
          }
          if (
            NIRV.tasks[i].type == NIRV.CONST.PROJECT &&
            NIRV.tasks[i].seqt == 0
          ) {
            if (
              NIRV.tasks[i].duedate != '' &&
              NIRV.tasks[i].duedate <= _today &&
              NIRV.tasks[i].seqt == 0
            ) {
              due.projects += plus(NIRV.tasks[i]);
            }
          }
          if (NIRV.tasks[i].parentid != '') {
            if (p[NIRV.tasks[i].parentid] != undefined) {
              p[NIRV.tasks[i].parentid].all++;
              if (NIRV.tasks[i].state == NIRV.CONST.NEXT) {
                p[NIRV.tasks[i].parentid].next++;
              }
              if (NIRV.tasks[i].seqt == 0) {
                if (
                  NIRV.tasks[i].duedate != '' &&
                  NIRV.tasks[i].duedate <= _today
                ) {
                  p[NIRV.tasks[i].parentid].due++;
                }
              }
            }
          }
        }
      }
    }
  }

  NIRV.taskcounts['due'] = due;
  NIRV.taskcounts['action'] = action;
  NIRV.taskcounts['standalone'] = standalone;
  NIRV.taskcounts['p'] = p;
  NIRV.taskcounts['l'] = l;
  NIRV.taskcounts['section'] = section;

  // RENDER
  var east = '';
  east = '#east';
  $(east + ' li span.count').empty();

  // COLLECT
  if (section.collect) {
    $(east)
      .find('.collect span.count')
      .html('<span class="action">' + section.collect + '</span>');
  }

  // -- inbox
  if (due.inbox == 0 && action.inbox != 0) {
    $(east + ' li[rel=inbox] span.count').html(
      '<span class="action">' + action.inbox + '</span>'
    );
  } else if (due.inbox != 0 && action.inbox == 0) {
    $(east + ' li[rel=inbox] span.count').html(
      '<span class="due">' + due.inbox + '</span>'
    );
  } else if (due.inbox != 0 && action.inbox != 0) {
    $(east + ' li[rel=inbox] span.count').html(
      '<span class="due-left">' +
        due.inbox +
        '</span><span class="action-right">' +
        action.inbox +
        '</span>'
    );
  }

  // ACTIONS
  if (section.actions) {
    $(east)
      .find('h3.actions span.count')
      .html('<span class="action">' + section.actions + '</span>');
  }

  // -- focus
  if (due.focus == 0 && action.focus != 0) {
    $(east + ' li[rel=focus] span.count').html(
      '<span class="action">' + action.focus + '</span>'
    );
  } else if (due.focus != 0 && action.focus == 0) {
    $(east + ' li[rel=focus] span.count').html(
      '<span class="due">' + due.focus + '</span>'
    );
  } else if (due.focus != 0 && action.focus != 0) {
    $(east + ' li[rel=focus] span.count').html(
      '<span class="due-left">' +
        due.focus +
        '</span><span class="action-right">' +
        action.focus +
        '</span>'
    );
  }
  // next
  if (due.next == 0 && action.next != 0) {
    $(east + ' li[rel=next] span.count').html(
      '<span class="action">' + action.next + '</span>'
    );
  } else if (due.next != 0 && action.next == 0) {
    $(east + ' li[rel=next] span.count').html(
      '<span class="due">' + due.next + '</span>'
    );
  } else if (due.next != 0 && action.next != 0) {
    $(east + ' li[rel=next] span.count').html(
      '<span class="due-left">' +
        due.next +
        '</span><span class="action-right">' +
        action.next +
        '</span>'
    );
  }
  // later
  if (due.later == 0 && action.later != 0) {
    $(east + ' li[rel=later] span.count').html(
      '<span class="action">' + action.later + '</span>'
    );
  } else if (due.later != 0 && action.later == 0) {
    $(east + ' li[rel=later] span.count').html(
      '<span class="due">' + due.later + '</span>'
    );
  } else if (due.later != 0 && action.later != 0) {
    $(east + ' li[rel=later] span.count').html(
      '<span class="due-left">' +
        due.later +
        '</span><span class="action-right">' +
        action.later +
        '</span>'
    );
  }
  // waiting
  if (due.waiting == 0 && action.waiting != 0) {
    $(east + ' li[rel=waiting] span.count').html(
      '<span class="action">' + action.waiting + '</span>'
    );
  } else if (due.waiting != 0 && action.waiting == 0) {
    $(east + ' li[rel=waiting] span.count').html(
      '<span class="due">' + due.waiting + '</span>'
    );
  } else if (due.waiting != 0 && action.waiting != 0) {
    $(east + ' li[rel=waiting] span.count').html(
      '<span class="due-left">' +
        due.waiting +
        '</span><span class="action-right">' +
        action.waiting +
        '</span>'
    );
  }
  // scheduled
  if (due.scheduled == 0 && action.scheduled != 0) {
    $(east + ' li[rel=scheduled] span.count').html(
      '<span class="action">' + action.scheduled + '</span>'
    );
  } else if (due.scheduled != 0 && action.scheduled == 0) {
    $(east + ' li[rel=scheduled] span.count').html(
      '<span class="due">' + due.scheduled + '</span>'
    );
  } else if (due.scheduled != 0 && action.scheduled != 0) {
    $(east + ' li[rel=scheduled] span.count').html(
      '<span class="due-left">' +
        due.scheduled +
        '</span><span class="action-right">' +
        action.scheduled +
        '</span>'
    );
  }
  // someday
  if (due.someday == 0 && action.someday != 0) {
    $(east + ' li[rel=someday] span.count').html(
      '<span class="action">' + action.someday + '</span>'
    );
  } else if (due.someday != 0 && action.someday == 0) {
    $(east + ' li[rel=someday] span.count').html(
      '<span class="due">' + due.someday + '</span>'
    );
  } else if (due.someday != 0 && action.someday != 0) {
    $(east + ' li[rel=someday] span.count').html(
      '<span class="due-left">' +
        due.someday +
        '</span><span class="action-right">' +
        action.someday +
        '</span>'
    );
  }

  // PROJECTS
  if (section.projects) {
    $(east)
      .find('.projects span.count:first')
      .html('<span class="action">' + section.projects + '</span>');
    if (NIRV.prefs.UICounts && NIRV.prefs.UICounts.value == 'all') {
      $(east)
        .find('li[rel=projects] span.count')
        .html('<span class="action">' + section.projects + '</span>');
    }
  }

  // projects
  // if (due.projects == 0 && action.projects != 0) {
  //     $(east + ' li[rel=projects] span.count').html('<span class="action">' + action.projects + '</span>');
  // }
  // else if (due.projects != 0 && action.projects == 0) {
  //     $(east + ' li[rel=projects] span.count').html('<span class="due">' + due.projects + '</span>');
  // }
  // else if (due.projects != 0 && action.projects != 0) {
  //     $(east + ' li[rel=projects] span.count').html('<span class="due-left">' + due.projects + '</span><span class="action-right">' + action.projects + '</span>');
  // }

  // each project
  for (var i in projects) {
    if (p[projects[i].id].due == 0 && p[projects[i].id].action != 0) {
      $(east + ' li[rel=p' + projects[i].id + '] span.count').html(
        '<span class="action">' + p[projects[i].id].action + '</span>'
      );
    } else if (p[projects[i].id].due != 0 && p[projects[i].id].action == 0) {
      $(east + ' li[rel=p' + projects[i].id + '] span.count').html(
        '<span class="due">' + p[projects[i].id].due + '</span>'
      );
    } else if (p[projects[i].id].due != 0 && p[projects[i].id].action != 0) {
      $(east + ' li[rel=p' + projects[i].id + '] span.count').html(
        '<span class="due-left">' +
          p[projects[i].id].due +
          '</span><span class="action-right">' +
          p[projects[i].id].action +
          '</span>'
      );
    }
  }

  // REFERENCE
  if (section.reflists) {
    $(east)
      .find('h3.reflists span.count:first')
      .html('<span class="action">' + section.reflists + '</span>');
    if (NIRV.prefs.UICounts && NIRV.prefs.UICounts.value == 'all') {
      $(east)
        .find('li[rel=reflists] span.count')
        .html('<span class="action">' + section.reflists + '</span>');
    }
  }

  // each reflist
  if (NIRV.prefs.UICounts && NIRV.prefs.UICounts.value == 'all') {
    for (var i in reflists) {
      if (l[reflists[i].id].all != 0) {
        $(east + ' li[rel=l' + reflists[i].id + '] span.count').html(
          '<span class="action">' + l[reflists[i].id].all + '</span>'
        );
      }
    }
  }

  // CONTEXTS
  $(east)
    .find('h3.contexts span.count')
    .html(
      '<span class="action">' +
        $(east).find('div.tagcloud li').length +
        '</span>'
    );

  // CLEANUP
  if (trash) {
    $(east)
      .find('h3.cleanup span.count')
      .html('<span class="action">' + trash + '</span>');
  }

  // trash
  if (trash == 0) {
    $(east + ' li.trash').addClass('empty');
  } else {
    $(east + ' li.trash').removeClass('empty');
    if (NIRV.prefs.UICounts && NIRV.prefs.UICounts.value == 'all') {
      $(east + ' li[rel=trash] span.count').html(
        '<span class="action">' + trash + '</span>'
      );
    }
  }
  // -- remaining views
  // if (due.next != 0) {
  //     $(east + ' li[rel=next] span.count').html('<span class="due">' + due.next + '</span>');
  // }
  // if (due.later != 0) {
  //     $(east + ' li[rel=later] span.count').html('<span class="due">' + due.later + '</span>');
  // }
  // if (due.scheduled != 0) {
  //     $(east + ' li[rel=scheduled] span.count').html('<span class="due">' + due.scheduled + '</span>');
  // }
  // if (due.someday != 0) {
  //     $(east + ' li[rel=someday] span.count').html('<span class="due">' + due.someday + '</span>');
  // }
  // if (due.projects != 0) {
  //     $(east + ' li[rel=projects] span.count').html('<span class="due">' + due.projects + '</span>');
  // }

  NIRV.taskcounts['trash'] = trash;
  NIRV.taskcounts['collect'] = collect;

  if (collect) {
    $('#north .button.cleanup')
      .removeClass('disabled')
      .find('.count')
      .html('&nbsp;' + collect);
  } else {
    $('#north .button.cleanup').addClass('disabled').find('.count').html('');
  }
};

// COLLECT COMPLETED ITEMS DAILY
NIRV.collectCompletedDaily = function () {
  DEBUG && console.log('  NIRV.collectCompletedDaily()');
  var counttasksupdated = 0;
  var _timestamp = time();
  // var midnight = moment().startOf('day').unix();
  var midnight = Date.today().getTime() / 1000;
  for (var i in NIRV.tasks) {
    if (
      NIRV.tasks[i].state != NIRV.CONST.TRASHED &&
      NIRV.tasks[i].state != NIRV.CONST.LOGGED &&
      NIRV.tasks[i].completed != 0 &&
      NIRV.tasks[i].completed < midnight
    ) {
      if (NIRV.tasks[i].type == NIRV.CONST.PROJECT) {
        NIRV.tasks[i].set('state', NIRV.CONST.LOGGED, _timestamp);
        NIRV.tasks[i].set('seq', 0, _timestamp);
        NIRV.tasks[i].set('seqt', 0, _timestamp);
        NIRV.tasks[i].set('seqp', 0, _timestamp);
        counttasksupdated++;
        for (var s in NIRV.tasks) {
          if (NIRV.tasks[s].parentid == NIRV.tasks[i].id) {
            NIRV.tasks[s].set('state', NIRV.CONST.LOGGED, _timestamp);
            NIRV.tasks[s].set('seq', 0, _timestamp);
            NIRV.tasks[s].set('seqt', 0, _timestamp);
            NIRV.tasks[s].set('seqp', 0, _timestamp);
            if (NIRV.tasks[s].completed == 0) {
              NIRV.tasks[s].set('completed', _timestamp, _timestamp);
              NIRV.tasks[s].set('cancelled', 1, _timestamp);
            }
            counttasksupdated++;
          }
        }
      } else if (NIRV.tasks[i].type == NIRV.CONST.TASK) {
        NIRV.tasks[i].set('state', NIRV.CONST.LOGGED, _timestamp);
        NIRV.tasks[i].set('seq', 0, _timestamp);
        NIRV.tasks[i].set('seqt', 0, _timestamp);
        NIRV.tasks[i].set('seqp', 0, _timestamp);
        counttasksupdated++;
      }
    }
  }

  NIRV.save();
  return counttasksupdated;
};

NIRV.collectCompleted = function (ask) {
  DEBUG && console.log('NIRV.collectCompleted()');
  let _collect = function () {
    var now = time();
    for (var i in NIRV.tasks) {
      if (
        NIRV.tasks[i] != undefined &&
        NIRV.tasks[i].state != NIRV.CONST.TRASHED &&
        NIRV.tasks[i].state != NIRV.CONST.LOGGED &&
        NIRV.tasks[i].completed != 0
      ) {
        if (NIRV.tasks[i].type == NIRV.CONST.PROJECT) {
          NIRV.tasks[i].set('state', NIRV.CONST.LOGGED);
          NIRV.tasks[i].set('seq', 0);
          NIRV.tasks[i].set('seqt', 0);
          NIRV.tasks[i].set('seqp', 0);
          for (var s in NIRV.tasks) {
            if (NIRV.tasks[s].parentid == NIRV.tasks[i].id) {
              NIRV.tasks[s].set('state', NIRV.CONST.LOGGED);
              NIRV.tasks[s].set('seq', 0);
              NIRV.tasks[s].set('seqt', 0);
              NIRV.tasks[s].set('seqp', 0);
              if (NIRV.tasks[s].completed == 0) {
                NIRV.tasks[s].set('completed', now);
                NIRV.tasks[s].set('cancelled', 1);
              }
            }
          }
        } else if (NIRV.tasks[i].type == NIRV.CONST.TASK) {
          NIRV.tasks[i].set('state', NIRV.CONST.LOGGED);
          NIRV.tasks[i].set('seq', 0);
          NIRV.tasks[i].set('seqt', 0);
          NIRV.tasks[i].set('seqp', 0);
        }
      }
    }
    NIRV.save();
    NIRV.updateCounts();
    NIRV.collapseCompletedTasklists();
    NIRV.refreshCurrentview();
  };

  if (ask) {
    NIRV.confirm('Move completed items to the Logbook now?', () => {
      _collect();
    });
  } else {
    _collect();
  }
};

NIRV.emptyTrash = function () {
  DEBUG && console.log('NIRV.emptyTrash()');
  NIRV.confirm(
    'Are you sure you want to permanently erase the items in the Trash?',
    () => {
      var now = time();
      for (var i in NIRV.tasks) {
        if (
          NIRV.tasks[i] != undefined &&
          NIRV.tasks[i].state == NIRV.CONST.TRASHED
        ) {
          if (NIRV.tasks[i].type == NIRV.CONST.PROJECT) {
            NIRV.tasks[i].set('state', NIRV.CONST.DELETED);
            NIRV.tasks[i].set('deleted', now);
            for (var s in NIRV.tasks) {
              if (NIRV.tasks[s].parentid == NIRV.tasks[i].id) {
                NIRV.tasks[s].set('state', NIRV.CONST.DELETED);
                NIRV.tasks[s].set('deleted', now);
              }
            }
          } else if (NIRV.tasks[i].type == NIRV.CONST.TASK) {
            NIRV.tasks[i].set('state', NIRV.CONST.DELETED);
            NIRV.tasks[i].set('deleted', now);
          }
        }
      }

      NIRV.save();
      NIRV.updateCounts();
      NIRV.collapseCompletedTasklists();
      NIRV.refreshCurrentview();
    }
  );
};

NIRV.refreshCurrentview = function () {
  DEBUG && console.log('NIRV.refreshCurrentview()');

  if (NIRV.editing()) return;

  NIRV.autosave = true;
  NIRV.filterForSelectedTags();
  NIRV.collapseCompletedTasklists();
  NIRV.updateCounts();

  if (NIRV.q != '') {
    NIRV.search(NIRV.q);
  } else if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
    NIRV.viewproject(NIRV.currentview.substr(1));
  } else if (
    NIRV.currentview[0] == 'l' &&
    NIRV.currentview != 'later' &&
    NIRV.currentview != 'logbook'
  ) {
    NIRV.viewreflist(NIRV.currentview.substr(1));
  } else if (NIRV.currentview[0] == 'g') {
    NIRV.viewtag(NIRV.currentview.substr(1));
  } else {
    NIRV.view(NIRV.currentview);
  }
};

NIRV.showhideTasklists = function () {
  // DEBUG && console.log('NIRV.showhidetasklists()');
  $('#main .tasklist').each(function () {
    var key = $(this).attr('key');
    var tasklist = NIRV.tasklists[key];
    if (tasklist) {
      var isempty = true; // init
      for (var i = 0; i < tasklist.tasks.length; i++) {
        if (tasklist.tasks[i].__hide__ == false) {
          isempty = false;
        }
      }
      if (isempty) {
        DEBUG && console.log(key + ' is empty');
        $('.tasklist[key="' + key + '"]').addClass('empty');
      } else {
        DEBUG && console.log(key + ' is not empty');
        $('.tasklist[key="' + key + '"]').removeClass('empty');
      }
    }
  });
};

// Whittle down to just those tasks we need on screen, based on 'filter'
// starting with NIRV.tasks and reducing to a filtered subset NIRV.wtasks
NIRV.recalcWtasks = function (filter) {
  DEBUG && console.log(' NIRV.recalcWtasks()');

  filter = filter || {};

  var currentarea = filter.area || NIRV.currentarea;
  var currentview = filter.view || NIRV.currentview;
  var currentsearch = filter.search || NIRV.currentsearch;

  var areas = NIRV.areas();
  var tasks = NIRV.tasks;
  var wtasks = {}; // result
  var t, task, a, i, p, q, searchthis;

  // DELETED
  for (t in tasks) {
    task = tasks[t];
    if (task.state != NIRV.CONST.DELETED) {
      wtasks[task.id] = task;
    }
  }

  // AREA FILTERING
  if (currentarea == '__ALL__') {
    // skip
  } else {
    for (t in wtasks) {
      task = wtasks[t];
      if (task.state == NIRV.CONST.INBOX || task.state == NIRV.CONST.TRASHED) {
        // skip
      } else {
        if (currentarea == '__NONE__') {
          for (a = 0; a < areas.length; a++) {
            if (task != undefined && task.__tags__().indexOf(areas[a]) != -1) {
              delete wtasks[t];
            }
          }
        } else {
          if (
            NIRV.prefs.UIAreaFiltering &&
            NIRV.prefs.UIAreaFiltering.value == 'exclusive'
          ) {
            if (
              task != undefined &&
              task.__tags__().indexOf(',' + currentarea + ',') == -1
            ) {
              delete wtasks[t];
            }
          } else {
            // inclusive
            var task_areas = [];
            for (var i = 0; i < areas.length; i++) {
              if (
                task != undefined &&
                task.__tags__().indexOf(',' + areas[i] + ',') != -1
              ) {
                task_areas.push(areas[i]);
              }
            }
            if (task != undefined && task_areas.length == 0) {
              // delete wtasks[t];
            } else if (
              task != undefined &&
              task.__tags__().indexOf(',' + currentarea + ',') == -1
            ) {
              delete wtasks[t];
            }
          }
        }
      }
    }
  }

  // VIEW FILTERING
  if (currentview == 'inbox') {
    for (t in wtasks) {
      task = wtasks[t];
      if (task != undefined && task.state != NIRV.CONST.INBOX) {
        delete wtasks[t];
      }
    }
  } else if (currentview == 'focus') {
    for (t in wtasks) {
      task = wtasks[t];
      if (task != undefined && task.seqt == 0) {
        delete wtasks[t];
      }
    }
  } else if (currentview == 'logbook') {
    for (t in wtasks) {
      task = wtasks[t];
      if (task != undefined && task.state != NIRV.CONST.LOGGED) {
        delete wtasks[t];
      }
    }
  } else if (currentview == 'trash') {
    for (t in wtasks) {
      task = wtasks[t];
      if (task != undefined && task.state != NIRV.CONST.TRASHED) {
        delete wtasks[t];
      }
    }
  } else if (currentview == 'search') {
    var parts = NIRV.q.toLowerCase().split(' ');

    if (parts[0] == 'in:inbox') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (task != undefined && task.state != NIRV.CONST.INBOX) {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:next') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (task != undefined && task.state != NIRV.CONST.NEXT) {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:scheduled') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (
          task != undefined &&
          (task.state == NIRV.CONST.SCHEDULED ||
            task.state == NIRV.CONST.RECURRING)
        ) {
          // ok
        } else {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:waiting') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (task != undefined && task.state != NIRV.CONST.WAITING) {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:someday') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (task != undefined && task.state != NIRV.CONST.SOMEDAY) {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:focus') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (
          task != undefined &&
          (task.seqt == 0 || task.state == NIRV.CONST.LOGGED)
        ) {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:projects') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (
          task != undefined &&
          (task.type != NIRV.CONST.PROJECT || task.state == NIRV.CONST.LOGGED)
        ) {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:reference') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (task != undefined && task.state != NIRV.CONST.REFERENCE) {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:logbook') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (task != undefined && task.state != NIRV.CONST.LOGGED) {
          delete wtasks[t];
        }
      }
    } else if (parts[0] == 'in:trash') {
      parts[0] = '';
      for (t in wtasks) {
        task = wtasks[t];
        if (task != undefined && task.state != NIRV.CONST.TRASHED) {
          delete wtasks[t];
        }
      }
    } else {
      for (t in wtasks) {
        task = wtasks[t];
        if (task != undefined && task.state == NIRV.CONST.LOGGED) {
          delete wtasks[t];
        }
      }
    }
    for (var i = 0; i < parts.length; i++) {
      q = parts[i];
      if (q != '' && q != '-') {
        for (t in wtasks) {
          task = wtasks[t];
          if (task != undefined) {
            searchthis = (
              task.name +
              ' ' +
              task.__tags__() +
              ' ' +
              task.waitingfor +
              ' ' +
              task.note
            ).toLowerCase();
            if (q.substr(0, 1) == '-') {
              if (searchthis.indexOf(q.substr(1)) > -1) {
                delete wtasks[t];
              }
            } else {
              if (searchthis.indexOf(q) == -1) {
                delete wtasks[t];
              }
            }
          }
        }
      }
    }
  }

  // reattach to global NIRV
  NIRV.wtasks = wtasks;
};

NIRV._viewInbox = function () {
  var init = {};

  $('#main').addClass('inbox');

  init = {
    key: 'inbox.1',
    params: { state: 0, completed: 0 },
    name: NIRV.L.inbox,
    sortby: 'seq',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
  ) {
    init = {
      key: 'inbox.completed',
      params: { state: 0, completed: 1 },
      name: 'Done',
      sortby: 'seq',
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  }
};

NIRV._viewFocus = function () {
  var init = {};

  $('#main').addClass('focus');

  // view option » due
  if (NIRV.currentfilters.due) {
    init = {
      key: 'focus.1',
      params: { focus: 1, completed: 0 },
      name: 'Due',
      sortby: 'duedate',
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  } else {
    // view option » group by state
    if (
      NIRV.prefs.UIBFocusGroupBy &&
      NIRV.prefs.UIBFocusGroupBy.value == 'state'
    ) {
      init = {
        key: 'focus.inbox',
        params: { focus: 1, type: 0, state: 0, completed: 0 },
        name: NIRV.L.inbox,
        sortby: 'seq',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      init = {
        key: 'focus.next',
        params: { focus: 1, type: 0, state: 1, completed: 0 },
        name: NIRV.L.next,
        sortby: 'seq',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      init = {
        key: 'focus.later',
        params: { focus: 1, state: 5, completed: 0 },
        name: 'Later',
        sortby: 'seq',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      init = {
        key: 'focus.waiting',
        params: { focus: 1, type: 0, state: 2, completed: 0 },
        name: NIRV.L.waiting,
        sortby: 'seq',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      init = {
        key: 'focus.scheduled',
        params: { focus: 1, scheduledandrecurring: 1, completed: 0 },
        name: NIRV.L.scheduled,
        sortby: 'startdate',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      init = {
        key: 'focus.someday',
        params: { focus: 1, state: 4, completed: 0 },
        name: NIRV.L.someday,
        sortby: 'seq',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      var projects_sortby =
        NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha'
          ? 'name'
          : 'seqt';
      init = {
        key: 'focus.activeprojects',
        params: { focus: 1, type: 1, state: 11, completed: 0 },
        name: NIRV.L.activeprojects,
        sortby: projects_sortby,
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      init = {
        key: 'reference',
        params: { focus: 1, state: NIRV.CONST.REFERENCE },
        name: 'Reference',
        addClass: 'reference',
        sortby: 'seq',
      };
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
      ) {
        init = {
          key: 'focus.completed',
          params: { focus: 1, completed: 1 },
          name: 'Done',
          sortby: 'completed',
        };
        NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
        $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
      }
    }

    // view option » group by project
    if (
      NIRV.prefs.UIBFocusGroupBy &&
      NIRV.prefs.UIBFocusGroupBy.value == 'parentid'
    ) {
      init = {
        key: 'focus.standalone',
        params: { focus: 1, type: 0, parentid: '', completed: 0 },
        name: 'Standalone Actions',
        sortby: 'seqt',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      var projects_sortby =
        NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha'
          ? 'name'
          : 'seqt';
      init = {
        key: 'focus.projects',
        params: { focus: 1, type: 1, parentid: '', completed: 0 },
        name: NIRV.L.projects,
        sortby: projects_sortby,
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      var projects = NIRV.projects();
      if (
        NIRV.prefs.UISortProjects &&
        NIRV.prefs.UISortProjects.value == 'alpha'
      ) {
        projects = sortEntitiesBy(projects, 'name', 'asc', false);
      }
      for (var i in projects) {
        if (projects[i].state == NIRV.CONST.ACTIVEPROJECT) {
          init = {
            key: 'focus.' + projects[i].id,
            params: {
              focus: 1,
              type: 0,
              parentid: projects[i].id,
              completed: 0,
            },
            name: projects[i].name,
            addClass: 'project',
            sortby: projects_sortby,
          };
          if (
            NIRV.prefs.UIBLeaveCompletedInPlace &&
            NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
          ) {
            delete init.params.completed;
          }
          NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
          $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
        }
      }

      init = {
        key: 'reference',
        params: { focus: 1, state: NIRV.CONST.REFERENCE },
        name: 'Reference',
        addClass: 'reference',
        sortby: 'seq',
      };
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
      ) {
        init = {
          key: 'focus.completed',
          params: { focus: 1, completed: 1 },
          name: 'Done',
          sortby: 'completed',
        };
        NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
        $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
      }
    }

    // view option » unified list
    else if (
      NIRV.prefs.UIBFocusGroupBy &&
      NIRV.prefs.UIBFocusGroupBy.value == ''
    ) {
      init = {
        key: 'focus.todo',
        params: { focus: 1, completed: 0 },
        name: 'Focus',
        sortby: 'seqt',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
      ) {
        init = {
          key: 'focus.completed',
          params: { focus: 1, completed: 1 },
          name: 'Done',
          sortby: 'completed',
        };
        NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
        $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
      }
    }
  }
};

NIRV._viewNext = function () {
  var init = {};

  // group by project
  if (
    NIRV.prefs.UIBNextGroupBy &&
    NIRV.prefs.UIBNextGroupBy.value == 'parentid'
  ) {
    $('#main').addClass('next');
    init = {
      key: 'next.standalone',
      params: { state: 1, parentid: '', completed: 0 },
      name: 'Actions',
      sortby: 'seq',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    var projects = NIRV.projects();
    if (
      NIRV.prefs.UISortProjects &&
      NIRV.prefs.UISortProjects.value == 'alpha'
    ) {
      projects = sortEntitiesBy(projects, 'name', 'asc', false);
    }
    for (var i in projects) {
      if (projects[i].state == NIRV.CONST.ACTIVEPROJECT) {
        init = {
          key: 'next.' + projects[i].id,
          params: { state: 1, parentid: projects[i].id, completed: 0 },
          name: projects[i].name,
          addClass: 'project',
          sortby: 'seqp',
        };
        if (
          NIRV.prefs.UIBLeaveCompletedInPlace &&
          NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
        ) {
          delete init.params.completed;
        }
        NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
        $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
      }
    }

    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
    ) {
      init = {
        key: 'next.completed',
        params: { state: 1, completed: 1 },
        name: 'Done',
        sortby: 'completed',
      };
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
    }
  }

  // group by sequential next actions
  else if (NIRV.prefs.UIBNextGroupBy && NIRV.prefs.UIBNextGroupBy.value == '') {
    $('#main').addClass('next2');
    init = {
      key: 'next.standalone',
      params: { state: 1, parentid: '', completed: 0 },
      name: 'Actions',
      sortby: 'seq',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    init = {
      key: 'next.seqentialdrilldown',
      params: { state: 1, top: 1, completed: 0 },
      name: 'Project Actions - Sequential Drilldown',
      sortby: 'seq',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
    ) {
      init = {
        key: 'next.completed',
        params: { state: 1, completed: 1 },
        name: 'Done',
        sortby: 'completed',
      };
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
    }
  }

  // UNL
  else if (
    NIRV.prefs.UIBNextGroupBy &&
    NIRV.prefs.UIBNextGroupBy.value == 'none'
  ) {
    init = {
      key: 'next.todo',
      params: { state: 1, completed: 0 },
      name: 'Actions',
      sortby: 'seq',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
    ) {
      init = {
        key: 'next.completed',
        params: { state: 1, completed: 1 },
        name: 'Done',
        sortby: 'completed',
      };
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
    }
  }
};

NIRV._viewLater = function () {
  var init = {};

  init = {
    key: 'later.tasks',
    params: { state: 5, type: 0, completed: 0 },
    name: 'Later',
    sortby: 'seq',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'later.projects',
    params: { state: 5, type: 1, completed: 0 },
    name: 'Projects (Inactive)',
    sortby: 'seq',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
  ) {
    init = {
      key: 'later.completed',
      params: { state: 5, completed: 1 },
      name: 'Done',
      sortby: 'completed',
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  }
};

NIRV._viewWaiting = function () {
  var init = {};

  init = {
    key: 'waiting.todo',
    params: { state: 2, completed: 0 },
    name: NIRV.L.waitingfor,
    sortby: 'seq',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
  ) {
    init = {
      key: 'waiting.completed',
      params: { state: 2, completed: 1 },
      name: 'Done',
      sortby: 'completed',
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  }
};

NIRV._viewScheduled = function () {
  var init = {};

  $('#main').addClass('scheduled');

  init = {
    key: 'scheduled.thisweek',
    params: { completed: 0, scheduledandrecurring: 'thisweek' },
    name: 'This Week',
    sortby: 'startdate',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'scheduled.nextweek',
    params: { completed: 0, scheduledandrecurring: 'nextweek' },
    name: 'Next Week',
    sortby: 'startdate',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'scheduled.afternextweek',
    params: { completed: 0, scheduledandrecurring: 'afternextweek' },
    name: 'Future Dates',
    sortby: 'startdate',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
  ) {
    init = {
      key: 'scheduled.completed',
      params: { completed: 1, state: NIRV.CONST.SCHEDULED },
      name: 'Done',
      sortby: 'completed',
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  }
};

NIRV._viewSomeday = function () {
  var init = {};

  $('#main').addClass('someday');

  // init = {
  //     key: '__state__4__type__0__completed__0',
  //     name: NIRV.L.someday,
  //     sortby: 'seq'
  // };
  // NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  // $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  //
  // init = {
  //     key: '__state__4__type__1__completed__0',
  //     name: 'Projects',
  //     sortby: 'seq'
  // };
  // NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  // $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  //
  // init = {
  //     key: '__state__4__completed__1',
  //     name: '&nbsp;',
  //     sortby: 'completed'
  // };
  // NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  // $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'someday.standalone',
    params: { state: 4, type: 0, parentid: '', completed: 0 },
    name: 'Actions',
    sortby: 'seq',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'someday.projects',
    params: { state: 4, type: 1, completed: 0 },
    name: 'Projects',
    sortby: 'seq',
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  var projects = NIRV.projects();
  for (var i in projects) {
    if (projects[i].state == NIRV.CONST.ACTIVEPROJECT) {
      init = {
        key: 'someday.' + projects[i].id,
        params: {
          state: 4,
          type: 0,
          parentid: projects[i].id,
          completed: 0,
        },
        name: projects[i].name,
        addClass: 'project',
        sortby: 'seqp',
      };
      if (
        NIRV.prefs.UIBLeaveCompletedInPlace &&
        NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
      ) {
        delete init.params.completed;
      }
      NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
      $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
    }
  }

  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
  ) {
    init = {
      key: 'someday.completed',
      params: { state: 4, completed: 1 },
      name: 'Done',
      sortby: 'completed',
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  }
};

NIRV._viewProjects = function () {
  var init = {};

  $('#main').addClass('projects');
  $('#east h3.projects').addClass('on');

  var sortby_active =
    NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha'
      ? 'name'
      : 'seq';
  var sortby_inactive =
    NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha'
      ? 'name'
      : 'seq';
  var sortby_scheduled =
    NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha'
      ? 'name'
      : 'startdate';
  var sortby_someday =
    NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha'
      ? 'name'
      : 'seq';
  var sortby_completed =
    NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha'
      ? 'name'
      : 'completed';

  init = {
    key: 'projects.active',
    params: { type: 1, state: 11, completed: 0 },
    name: NIRV.L.activeprojects,
    sortby: sortby_active,
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'projects.inactive',
    params: { type: 1, state: 5, completed: 0 },
    name: NIRV.L.inactive,
    sortby: sortby_inactive,
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'projects.scheduled',
    params: { type: 1, scheduledandrecurring: 1, completed: 0 },
    name: NIRV.L.scheduled,
    sortby: sortby_scheduled,
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'projects.someday',
    params: { type: 1, state: 4, completed: 0 },
    name: NIRV.L.someday,
    sortby: sortby_someday,
  };
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
  ) {
    init = {
      key: 'projects.completed',
      params: { type: 1, completed: 1, logged: 0 },
      name: 'Done',
      sortby: sortby_completed,
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  }
};

NIRV._viewRefLists = function () {
  var init = {};

  $('#main').addClass('reflists');
  $('#east h3.reflists').addClass('on');

  var sortby_reflists =
    NIRV.prefs.UISortReflists && NIRV.prefs.UISortReflists.value == 'alpha'
      ? 'name'
      : 'seq';

  init = {
    key: 'reflists',
    params: { type: NIRV.CONST.REFLIST },
    name: 'Reference Lists',
    sortby: sortby_reflists,
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
};

NIRV._viewLogbook = function () {
  var init = {};

  $('#main').addClass('logbook');

  if (NIRV.taskcounts.collect > 0) {
    $('#tasklists').append(
      '<div class="toolbar"><a href="#" class="fn awesome primary" fn="NIRV.collectCompleted" p1="0">Log Completed (' +
        NIRV.taskcounts.collect +
        ')</a></div>'
    );
  }

  init = {
    key: 'logbook.today',
    params: { completed: 'today', logged: 1 },
    name: 'Today',
    sortby: 'completed',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'logbook.active',
    params: { completed: 'thisweek', logged: 1 },
    name: 'This Week',
    sortby: 'completed',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'logbook.lastweek',
    params: { completed: 'lastweek', logged: 1 },
    name: 'Last Week',
    sortby: 'completed',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'logbook.twoweeksplus',
    params: { completed: 'twoweeksplus', logged: 1 },
    name: 'Older',
    sortby: 'completed',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
};

NIRV._viewTrash = function () {
  var init = {};

  $('#main').addClass('trash');

  if (NIRV.taskcounts.trash > 0) {
    $('#tasklists').prepend(
      '<div class="toolbar"><a href="#" class="fn awesome primary" fn="NIRV.emptyTrash">Empty Trash</a></div>'
    );
  }

  init = {
    key: 'trash',
    params: { state: 6 },
    name: NIRV.L.trash,
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
};

NIRV.view = function (what) {
  DEBUG && console.log('-----------------------\nNIRV.view(' + what + ')');
  $(window).scrollTop(0);

  if (NIRV.currentview != what) {
    NIRV.viewinit();
    NIRV.currentview = what;
    NIRV.storage.setItem('NIRV.currentview', NIRV.currentview);
  }
  NIRV.recalcWtasks();

  NIRV.filterForSelectedTags();
  $('#east h3').removeClass('on');
  $('#east span.tag').removeClass('on');
  $('#east li').removeClass('on');
  $('#east li[rel="' + NIRV.currentview + '"]').addClass('on');

  $('#main').removeClass(
    'inbox next next2 later waiting scheduled someday focus projects project inactive reflist logbook trash'
  );

  $('#tasklists').empty();

  NIRV.autosave = false;

  switch (what) {
    case 'inbox':
      NIRV._viewInbox();
      break;

    case 'focus':
      NIRV._viewFocus();
      break;

    case 'next':
      NIRV._viewNext();
      break;

    case 'later':
      NIRV._viewLater();
      break;

    case 'waiting':
      NIRV._viewWaiting();
      break;

    case 'scheduled':
      NIRV._viewScheduled();
      break;

    case 'someday':
      NIRV._viewSomeday();
      break;

    case 'projects':
      NIRV._viewProjects();
      break;

    case 'reflists':
      NIRV._viewRefLists();
      break;

    case 'logbook':
      NIRV._viewLogbook();
      break;

    case 'trash':
      NIRV._viewTrash();
      break;
  }

  NIRV.refreshAll();
  NIRV.autosave = true;
};

NIRV.viewproject = function (pid) {
  DEBUG &&
    console.log('-----------------------\nNIRV.viewproject( ' + pid + ' )');
  $(window).scrollTop(0);

  var init = {};
  var project = NIRV.tasks[pid];

  if (project === undefined) {
    location.hash = '#projects';
    return;
  }

  if (project.type == NIRV.CONST.REFLIST) {
    NIRV.viewreflist(pid);
    return;
  }

  if (NIRV.currentview != 'p' + pid) {
    NIRV.viewinit();
    NIRV.currentview = 'p' + pid;
    NIRV.storage.setItem('NIRV.currentview', NIRV.currentview);
  }
  NIRV.recalcWtasks();

  NIRV.filterForSelectedTags();

  $('#east h3').removeClass('on');
  $('#east span.tag').removeClass('on');
  $('#east li').removeClass('on');
  $('#east li[rel="' + NIRV.currentview + '"]').addClass('on');

  $('#main')
    .removeClass(
      'inbox next next2 later waiting scheduled someday focus projects project inactive reflist logbook trash'
    )
    .addClass('project');

  if (project.state != NIRV.CONST.ACTIVEPROJECT) {
    $('#main').addClass('inactive');
  }

  $('#tasklists').empty();

  NIRV.autosave = false;

  if (project.__isasequentialproject__()) {
    init = {
      key: 'project.nextsteps',
      params: { parentid: project.id, nextsteps: 1, completed: 0 },
      name: 'Next Up',
      addClass: 'project nextsteps',
      sortby: 'seqp',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    init = {
      key: 'project.later',
      params: { parentid: project.id, state: 5, completed: 0 },
      name: 'Later',
      addClass: 'project later',
      sortby: 'seqp',
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    init = {
      key: 'project.repeating',
      params: { parentid: project.id, state: 9 },
      name: 'Repeating',
      addClass: 'project repeating',
      sortby: 'seqp',
    };
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    init = {
      key: 'project.someday',
      params: { parentid: project.id, state: 4, completed: 0 },
      name: NIRV.L.someday,
      addClass: 'project someday',
      sortby: 'seqp',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      init = {
        key: 'project.logged',
        params: { parentid: project.id, logged: 1 },
        name: 'Logged',
        addClass: 'project logged',
        sortby: 'completed',
      };
    } else {
      init = {
        key: 'project.completed',
        params: { parentid: project.id, completed: 1 },
        name: 'Done',
        addClass: 'project completed',
        sortby: 'completed',
      };
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  } else {
    init = {
      key: 'project.next',
      params: { parentid: project.id, state: 1, completed: 0 },
      name: 'Next',
      addClass: 'project next',
      sortby: 'seqp',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    init = {
      key: 'project.later',
      params: { parentid: project.id, state: 5, completed: 0 },
      name: 'Later',
      addClass: 'project later',
      sortby: 'seqp',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    init = {
      key: 'project.waiting',
      params: { parentid: project.id, state: 2, completed: 0 },
      name: NIRV.L.waiting,
      addClass: 'project waiting',
      sortby: 'seqp',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    init = {
      key: 'project.scheduled',
      params: {
        parentid: project.id,
        scheduledandrecurring: 1,
        completed: 0,
      },
      name: NIRV.L.scheduled,
      addClass: 'project scheduledandrecurring',
      sortby: 'startdate',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    init = {
      key: 'project.someday',
      params: { parentid: project.id, state: 4, completed: 0 },
      name: NIRV.L.someday,
      addClass: 'project someday',
      sortby: 'seqp',
    };
    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      delete init.params.completed;
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

    if (
      NIRV.prefs.UIBLeaveCompletedInPlace &&
      NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
    ) {
      init = {
        key: 'project.logged',
        params: { parentid: project.id, logged: 1 },
        name: 'Logged',
        addClass: 'project logged',
        sortby: 'completed',
      };
    } else {
      init = {
        key: 'project.completed',
        params: { parentid: project.id, completed: 1 },
        name: 'Done',
        addClass: 'project completed',
        sortby: 'completed',
      };
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  }

  NIRV.refreshAll();
  NIRV.autosave = true;
};

NIRV.viewreflist = function (pid) {
  DEBUG &&
    console.log('-----------------------\nNIRV.viewreflist(' + pid + ')');
  $(window).scrollTop(0);

  var init = {};
  var reflist = NIRV.tasks[pid];

  if (reflist === undefined) {
    location.hash = '#reflists';
    return;
  }

  if (reflist.type == NIRV.CONST.PROJECT) {
    NIRV.viewproject(pid);
    return;
  }

  if (NIRV.currentview != 'l' + pid) {
    NIRV.viewinit();
    NIRV.currentview = 'l' + pid;
    NIRV.storage.setItem('NIRV.currentview', NIRV.currentview);
  }
  NIRV.recalcWtasks();

  NIRV.filterForSelectedTags();

  $('#east h3').removeClass('on');
  $('#east span.tag').removeClass('on');
  $('#east li').removeClass('on');
  $('#east li[rel="' + NIRV.currentview + '"]').addClass('on');

  $('#main')
    .removeClass(
      'inbox next next2 later waiting scheduled someday focus projects project inactive reflist logbook trash'
    )
    .addClass('reflist');

  $('#tasklists').empty();

  NIRV.autosave = false;

  init = {
    key: 'reflist',
    params: { type: NIRV.CONST.REFITEM, parentid: reflist.id },
    name: '',
    addClass: 'reflist',
    sortby: 'seq',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  NIRV.refreshAll();
  NIRV.autosave = true;
};

NIRV.viewtag = function viewtag(q) {
  DEBUG && console.log('-----------------------\nNIRV.viewtag(' + q + ')');

  var init = {};

  if (NIRV.currentview != 'g' + q) {
    NIRV.viewinit();
    NIRV.currentview = 'g' + q;
    NIRV.storage.setItem('NIRV.currentview', NIRV.currentview);
  }
  NIRV.recalcWtasks();

  NIRV.filterForSelectedTags();

  $('#east h3').removeClass('on');
  $('#east li').removeClass('on');
  $('#east li.tag').removeClass('on');
  if (q == '__ALL__') {
    $('#east li.tag.all').addClass('on');
  } else if (q == '__NONE__') {
    $('#east li.tag.none').addClass('on');
  } else {
    $('#east li.tag:econtains("' + q + '")').addClass('on');
  }

  $('#main').removeClass(
    'inbox next next2 later waiting scheduled someday focus projects project inactive reflist logbook trash'
  );

  $('#tasklists').empty();

  if (q.length == 0) return;

  NIRV.autosave = false;

  init = {
    key: 'tag.inbox',
    params: { completed: 0, state: 0, tag: q },
    name: NIRV.L.inbox,
    sortby: 'seq',
  };
  if (q == '__ALL__') {
    init.params = { completed: 0, state: 0 };
  }
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'tag.next',
    params: { completed: 0, state: 1, suspended: 0, tag: q },
    name: NIRV.L.next,
    sortby: 'seq',
  };
  if (q == '__ALL__') {
    init.params = { completed: 0, state: 1, suspended: 0 };
  }
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  // init = {
  //     key: 'tag.next.inactive',
  //     params: { completed:0, state:1, suspended:1, tag:q },
  //     name: NIRV.L.next + ' (inactive)',
  //     sortby: 'seq'
  // };
  // if (q == '__ALL__') { init.params = { completed:0, state:1, suspended: 1 }; }
  // NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  // $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'tag.later',
    params: { completed: 0, type: 0, state: 5, tag: q },
    name: 'Later',
    sortby: 'seq',
  };
  if (q == '__ALL__') {
    init.params = { completed: 0, type: 0, state: 5 };
  }
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    // key: '__type__0__state__2__tag__' + q,
    key: 'tag.waiting',
    params: { completed: 0, type: 0, state: 2, tag: q },
    name: NIRV.L.waiting,
    sortby: 'seq',
  };
  if (q == '__ALL__') {
    init.params = { completed: 0, type: 0, state: 2 };
  }
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'tag.scheduled',
    params: { completed: 0, scheduledandrecurring: 1, tag: q },
    name: NIRV.L.scheduled,
    sortby: 'startdate',
  };
  if (q == '__ALL__') {
    init.params = { completed: 0, type: 0, scheduledandrecurring: 1 };
  }
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'tag.someday',
    params: { completed: 0, state: 4, tag: q },
    name: NIRV.L.someday,
    sortby: 'seq',
  };
  if (q == '__ALL__') {
    init.params = { completed: 0, type: 0, state: 4 };
  }
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  var projects_sortby = 'seq';
  if (NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha') {
    projects_sortby = 'name';
  }

  init = {
    key: 'tag.projects',
    params: { completed: 0, state: 11, logged: 0, tag: q },
    name: NIRV.L.activeprojects,
    sortby: projects_sortby,
  };
  if (q == '__ALL__') {
    init.params = { completed: 0, type: 1, logged: 0 };
  }
  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '1'
  ) {
    delete init.params.completed;
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  var reflists_sortby = 'seq';
  if (NIRV.prefs.UISortReflists && NIRV.prefs.UISortReflists.value == 'alpha') {
    reflists_sortby = 'name';
  }

  init = {
    key: 'tag.reflist',
    params: { type: NIRV.CONST.REFLIST, tag: q },
    name: 'Reference Lists',
    addClass: 'reflist',
    sortby: reflists_sortby,
  };
  if (q == '__ALL__') {
    init.params = { type: NIRV.CONST.REFLIST };
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'tag.refitem',
    params: { type: NIRV.CONST.REFITEM, tag: q },
    name: 'Reference Items',
    addClass: 'refitem',
    sortby: 'name',
  };
  if (q == '__ALL__') {
    init.params = { type: NIRV.CONST.REFITEM };
  }
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  if (
    NIRV.prefs.UIBLeaveCompletedInPlace &&
    NIRV.prefs.UIBLeaveCompletedInPlace.value == '0'
  ) {
    init = {
      key: 'tag.completed',
      params: { completed: 1, logged: 0, trashed: 0, tag: q },
      name: 'Done',
      sortby: 'completed',
    };
    if (q == '__ALL__') {
      init.params = { completed: 1, logged: 0, trashed: 0 };
    }
    NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
    $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());
  }

  NIRV.refreshAll();
  NIRV.autosave = true;
};

NIRV.search = function search(q) {
  DEBUG && console.log('-----------------------\nNIRV.search(' + q + ')');
  $(window).scrollTop(0);

  var init = {};

  NIRV.currentview = 'search';
  NIRV.storage.setItem('NIRV.currentview', NIRV.currentview);
  NIRV.recalcWtasks();

  NIRV.filterForSelectedTags();

  $('#main').removeClass(
    'inbox next next2 later waiting scheduled someday focus projects project inactive reflist logbook trash'
  );

  $('#east h3').removeClass('on');
  $('#east li').removeClass('on');
  $('#east span.tag').removeClass('on');

  $('#tasklists').empty();

  if (q.length == 0) return;

  NIRV.autosave = false;

  init = {
    // key: '__state__0__search__' + q,
    key: 'search.inbox',
    params: { state: 0, search: q },
    name: NIRV.L.inbox,
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    // key: '__state__1__search__' + q,
    key: 'search.next',
    params: { state: 1, search: q },
    name: NIRV.L.next,
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    // key: '__type__0__state__5__search__' + q,
    key: 'search.later',
    params: { state: 5, search: q },
    name: 'Later',
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    // key: '__type__0__state__2__search__' + q,
    key: 'search.waiting',
    params: { type: 0, state: 2, search: q },
    name: NIRV.L.waiting,
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    // key: '__type__0__scheduledandrecurring__1__search__' + q,
    key: 'search.scheduled',
    params: { type: 0, scheduledandrecurring: 1, search: q },
    name: NIRV.L.scheduled,
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    // key: '__type__0__state__4__search__' + q,
    key: 'search.someday',
    params: { type: 0, state: 4, search: q },
    name: NIRV.L.someday,
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    // key: '__type__1__logged__0__search__' + q,
    key: 'search.projects',
    params: { type: 1, logged: 0, search: q },
    name: NIRV.L.projects,
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'reflist',
    params: { type: NIRV.CONST.REFLIST, search: q },
    name: 'Reference Lists',
    addClass: 'reflist',
    sortby: 'seq',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    key: 'refitem',
    params: { type: NIRV.CONST.REFITEM, search: q },
    name: 'Reference',
    addClass: 'refitem',
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  init = {
    // key: '__state__7__search__' + q,
    key: 'search.logbook',
    params: { state: 7, search: q },
    name: NIRV.L.logbook,
    sortby: 'name',
  };
  NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  // init = {
  //     // key: '__state__6__search__' + q,
  //     key: 'search.trash',
  //     params: { state:6, search:q },
  //     name: NIRV.L.trash,
  //     sortby: 'name'
  // };
  // NIRV.tasklists[init.key] = NIRV.Tasklist.clone(init);
  // $('#tasklists').append(NIRV.tasklists[init.key].recalc().render());

  // NIRV.refreshCbar();
  // NIRV.refreshMain();
  // NIRV.hideEmptyTasklists();
  NIRV.refreshAll();
  NIRV.autosave = true;
};

NIRV.projects = function (area) {
  area = area || '__ALL__';
  var projects = [];
  for (var t in NIRV.tasks) {
    if (NIRV.tasks[t].type == NIRV.CONST.PROJECT) {
      if (area == '__ALL__') {
        projects.push(NIRV.tasks[t]);
      } else if (area == '__NONE__') {
        var areas = NIRV.areas();
        var found = false;
        for (var a = 0; a < areas.length; a++) {
          if (NIRV.tasks[t].tags.indexOf(',' + areas[a] + ',') != -1) {
            found = true;
          }
        }
        if (!found) {
          projects.push(NIRV.tasks[t]);
        }
      } else {
        if (
          NIRV.prefs.UIAreaFiltering &&
          NIRV.prefs.UIAreaFiltering.value == 'exclusive'
        ) {
          if (NIRV.tasks[t].tags.indexOf(',' + area + ',') != -1) {
            projects.push(NIRV.tasks[t]);
          }
        } else {
          // inclusive
          var NIRV_areas = NIRV.areas();
          var task_areas = [];
          for (var i = 0; i < NIRV_areas.length; i++) {
            if (
              NIRV.tasks[t].__tags__().indexOf(',' + NIRV_areas[i] + ',') != -1
            ) {
              task_areas.push(NIRV_areas[i]);
            }
          }
          if (task_areas.length == 0) {
            projects.push(NIRV.tasks[t]);
          } else if (
            NIRV.tasks[t].__tags__().indexOf(',' + NIRV.currentarea + ',') != -1
          ) {
            projects.push(NIRV.tasks[t]);
          }
        }
      }
    }
  }
  return sortEntitiesBy(projects, 'seq', 'asc', true);
};

NIRV.projectsCount = function () {
  var i = 0;
  for (var t in NIRV.tasks) {
    if (NIRV.tasks[t].type == NIRV.CONST.PROJECT) {
      if (NIRV.tasks[t].state != NIRV.CONST.LOGGED) {
        i++;
      }
    }
  }
  return i;
};

NIRV.reflistsCount = function () {
  var i = 0;
  for (var t in NIRV.tasks) {
    if (NIRV.tasks[t].type == NIRV.CONST.REFLIST) {
      i++;
    }
  }
  return i;
};

NIRV.reflists = function (area) {
  area = area || '__ALL__';
  var reflists = [];
  for (var t in NIRV.tasks) {
    if (NIRV.tasks[t].type == NIRV.CONST.REFLIST) {
      if (area == '__ALL__') {
        reflists.push(NIRV.tasks[t]);
      } else if (area == '__NONE__') {
        var areas = NIRV.areas();
        var found = false;
        for (var a = 0; a < areas.length; a++) {
          if (NIRV.tasks[t].tags.indexOf(',' + areas[a] + ',') != -1) {
            found = true;
          }
        }
        if (!found) {
          reflists.push(NIRV.tasks[t]);
        }
      } else {
        if (
          NIRV.prefs.UIAreaFiltering &&
          NIRV.prefs.UIAreaFiltering.value == 'exclusive'
        ) {
          if (NIRV.tasks[t].tags.indexOf(',' + area + ',') != -1) {
            reflists.push(NIRV.tasks[t]);
          }
        } else {
          // inclusive
          var NIRV_areas = NIRV.areas();
          var task_areas = [];
          for (var i = 0; i < NIRV_areas.length; i++) {
            if (
              NIRV.tasks[t].__tags__().indexOf(',' + NIRV_areas[i] + ',') != -1
            ) {
              task_areas.push(NIRV_areas[i]);
            }
          }
          if (task_areas.length == 0) {
            reflists.push(NIRV.tasks[t]);
          } else if (
            NIRV.tasks[t].__tags__().indexOf(',' + NIRV.currentarea + ',') != -1
          ) {
            reflists.push(NIRV.tasks[t]);
          }
        }
      }
    }
  }
  return sortEntitiesBy(reflists, 'seq', 'asc', true);
};

NIRV.filterForSelectedTags = function () {
  DEBUG && console.log(' NIRV.filterForSelectedTags()');

  var maintasks = {};
  var taskids = [];
  var taskid = '';
  var task = {};
  var tags = '';
  var atags = [];
  var tasktagcount = 0;
  var taskareatagcount = 0;
  var i, t;
  var showall = true;

  // start with everything visible
  $('#main li.task').each(function () {
    taskid = $(this).attr('taskid');
    if (NIRV.tasks[taskid] != undefined) {
      maintasks[taskid] = NIRV.tasks[taskid];
      maintasks[taskid].__hide__ = false;
    }
  });

  // due toggle
  if (NIRV.currentfilters.due) {
    for (var i in maintasks) {
      if (maintasks[i].duedate == '' || maintasks[i].completed != 0) {
        maintasks[i].__hide__ = true;
        showall = false;
      }
    }
  }

  // filter by etime
  if (NIRV.currentfilters.etime != -1) {
    for (var i in maintasks) {
      // if (maintasks[i].type == NIRV.CONST.TASK) {
      if (NIRV.currentfilters.etime == 0) {
        if (maintasks[i].etime != 0 && maintasks[i].completed == 0) {
          maintasks[i].__hide__ = true;
          showall = false;
        }
      } else {
        if (
          maintasks[i].etime == 0 ||
          maintasks[i].etime > NIRV.currentfilters.etime ||
          maintasks[i].completed != 0
        ) {
          maintasks[i].__hide__ = true;
          showall = false;
        }
      }
      // }
    }
  }

  // filter by energy
  if (NIRV.currentfilters.energy != -1) {
    for (var i in maintasks) {
      // if (maintasks[i].type == NIRV.CONST.TASK) {
      if (
        maintasks[i].energy != NIRV.currentfilters.energy ||
        maintasks[i].completed != 0
      ) {
        maintasks[i].__hide__ = true;
        showall = false;
      }
      // }
    }
  }

  // whittle down further
  if (NIRV.currentfilters.tags[0] == '__NONE__') {
    for (var i in maintasks) {
      tags = maintasks[i].__tags__();
      if (NIRV.currentarea == '__ALL__' || NIRV.currentarea == '__NONE__') {
        tags = tags.replace(/,/g, '');
        tags = tags.trim();
        for (t = 0; t < NIRV.currentfilters.tags.length; t++) {
          if (tags != '') {
            maintasks[i].__hide__ = true;
            showall = false;
          }
        }
      } else {
        atags = tags.split(',');
        tasktagcount = 0;
        taskareatagcount = 0;
        for (t = 0; t < atags.length; t++) {
          if (atags[t] != '') {
            tasktagcount++;
          }
          if (
            NIRV.tags[atags[t]] &&
            NIRV.tags[atags[t]].type == NIRV.CONST.AREA
          ) {
            taskareatagcount++;
          }
        }
        if (tasktagcount != taskareatagcount) {
          maintasks[i].__hide__ = true;
          showall = false;
        }
      }
    }
  } else {
    for (var i in maintasks) {
      tags = maintasks[i].__tags__() + ',' + maintasks[i].waitingfor + ',';
      atags = tags.split(',');
      for (t = 0; t < NIRV.currentfilters.tags.length; t++) {
        var found = false;
        if ($.inArray(NIRV.currentfilters.tags[t], atags) >= 0) {
          found = true;
        }
        if (!found) {
          maintasks[i].__hide__ = true;
          showall = false;
        }
      }
    }
  }

  if (showall && NIRV.prefs.UIBProjectNextActionsTopN) {
    NIRV.topn = NIRV.prefs.UIBProjectNextActionsTopN.value;
  } else {
    NIRV.topn = 3000;
  }
};

NIRV.hideEmptyTasklists = function () {
  DEBUG && console.log(' NIRV.hideEmptyTasklists()');
  $('#main .tasklist').each(function () {
    var countall = $(this).find('li.task').length;
    var counthidden = $(this).find('li.task.hide').length;
    var countvisible = countall - counthidden;
    if (counthidden == countall) {
      $(this).addClass('empty');
    } else {
      $(this).removeClass('empty');
    }
    $(this)
      .find('div.name span.count')
      .html(' &nbsp;' + countvisible + ' items hidden');
  });
  NIRV.reflow();
};

NIRV.calcnextdates = function (recurring) {
  // DEBUG && console.log('NIRV.calcnextdates()');

  var fromdate = new Date(yyyymmdd2mmddyyyy(recurring.nextdate)); // this will incrementally shift during calculations
  var repeat = cloneObject(recurring); //
  var series = [];
  var returnseries = [];

  var loopcount = 0;
  var done = false;

  // sanity cleansing for type
  if (recurring.interval != undefined) {
    recurring.interval = parseInt(recurring.interval, 10);
  }
  if (recurring.count != undefined) {
    recurring.count = parseInt(recurring.count, 10);
  }

  // DEBUG && console.log('recurring: ');
  // DEBUG && console.log(recurring);
  // DEBUG && console.log('recurring.nextdate: ');
  // DEBUG && console.log(recurring.nextdate);
  // DEBUG && console.log('fromdate: ');
  // DEBUG && console.log(fromdate);

  // STEP 1. Generate Series
  do {
    if (repeat.freq == 'daily') {
      var refdate = fromdate.clone();
      for (var c = 0; c < 2; c++) {
        series.push(new Date(refdate));
        refdate.addDays(repeat.interval);
      }
    }

    if (repeat.freq == 'weekly') {
      if (typeof fromdate !== 'object') {
        return [];
      }
      var refdate =
        fromdate.getDay() == 0
          ? fromdate.clone()
          : fromdate.clone().moveToDayOfWeek(0, -1);
      for (var c = 0; c < 2; c++) {
        for (var i in repeat.on) {
          var day = 0;
          switch (repeat.on[i]) {
            case 'sun':
              var day = 0;
              break;
            case 'mon':
              var day = 1;
              break;
            case 'tue':
              var day = 2;
              break;
            case 'wed':
              var day = 3;
              break;
            case 'thu':
              var day = 4;
              break;
            case 'fri':
              var day = 5;
              break;
            case 'sat':
              var day = 6;
              break;
          }
          var ndate = new Date(refdate).clone();
          if (day == 0) {
            series.push(ndate);
            series.push(ndate.clone().addWeeks(repeat.interval));
          } else {
            series.push(ndate.addDays(day));
            series.push(ndate.clone().addWeeks(repeat.interval));
          }
        }
      }
    }

    if (repeat.freq == 'monthly') {
      var refdate = fromdate.clone().moveToFirstDayOfMonth();
      for (var c = 0; c < 2; c++) {
        for (var i in repeat.on) {
          if (repeat.on[i].day == 'day') {
            if (repeat.on[i].nth == 'last') {
              series.push(new Date(refdate).clone().moveToLastDayOfMonth());
            } else {
              series.push(
                new Date(refdate).clone().addDays(repeat.on[i].nth - 1)
              );
            }
          } else {
            var day = 0;
            switch (repeat.on[i].day) {
              case 'sun':
                day = 0;
                break;
              case 'mon':
                day = 1;
                break;
              case 'tue':
                day = 2;
                break;
              case 'wed':
                day = 3;
                break;
              case 'thu':
                day = 4;
                break;
              case 'fri':
                day = 5;
                break;
              case 'sat':
                day = 6;
                break;
            }
            if (repeat.on[i].nth == 'last') {
              series.push(
                new Date(refdate).clone().moveToNthOccurrence(day, -1)
              );
            } else {
              series.push(
                new Date(refdate)
                  .clone()
                  .moveToNthOccurrence(day, repeat.on[i].nth)
              );
            }
          }
        }
        refdate.addMonths(repeat.interval);
      }
    }

    if (repeat.freq == 'yearly') {
      var refdate = new Date('01/01/' + fromdate.getFullYear());
      for (var c = 0; c < 2; c++) {
        for (var i in repeat.on) {
          if (repeat.on[i].day == 'day') {
            if (repeat.on[i].nth == 'last') {
              if (repeat.on[i].month == 1) {
                series.push(new Date(refdate).clone().moveToLastDayOfMonth());
              } else {
                series.push(
                  new Date(refdate)
                    .clone()
                    .moveToMonth(repeat.on[i].month - 1)
                    .moveToLastDayOfMonth()
                );
              }
            } else {
              if (repeat.on[i].month == 1) {
                series.push(
                  new Date(refdate).clone().addDays(repeat.on[i].nth - 1)
                );
              } else {
                series.push(
                  new Date(refdate)
                    .clone()
                    .moveToMonth(repeat.on[i].month - 1)
                    .addDays(repeat.on[i].nth - 1)
                );
              }
            }
          } else {
            var day = 0;
            switch (repeat.on[i].day) {
              case 'sun':
                day = 0;
                break;
              case 'mon':
                day = 1;
                break;
              case 'tue':
                day = 2;
                break;
              case 'wed':
                day = 3;
                break;
              case 'thu':
                day = 4;
                break;
              case 'fri':
                day = 5;
                break;
              case 'sat':
                day = 6;
                break;
            }
            if (repeat.on[i].nth == 'last') {
              if (repeat.on[i].month == 1) {
                series.push(
                  new Date(refdate).clone().moveToNthOccurrence(day, -1)
                );
              } else {
                series.push(
                  new Date(refdate)
                    .clone()
                    .moveToMonth(repeat.on[i].month - 1)
                    .moveToNthOccurrence(day, -1)
                );
              }
            } else {
              if (repeat.on[i].month == 1) {
                series.push(
                  new Date(refdate)
                    .clone()
                    .moveToNthOccurrence(day, repeat.on[i].nth)
                );
              } else {
                series.push(
                  new Date(refdate)
                    .clone()
                    .moveToMonth(repeat.on[i].month - 1)
                    .moveToNthOccurrence(day, repeat.on[i].nth)
                );
              }
            }
          }
        }
        refdate.addYears(repeat.interval);
      }
    }

    fromdate = series[series.length - 1];

    loopcount++;
    if (loopcount > 14) {
      done = true;
    }
    if (
      recurring.until != undefined &&
      fromdate.toString('yyyyMMdd') >= recurring.until
    ) {
      done = true;
    }
    if (recurring.count != undefined && loopcount >= recurring.count) {
      done = true;
    }
  } while (!done);

  // convert "series" array elements from Date to String, so that we can sort the array
  for (var s = 0; s < series.length; s++) {
    series[s] = series[s].toString('yyyyMMdd');
  }
  series.sort();
  series = unique(series);

  fromdate = fromdate.toString('yyyyMMdd');
  var count = 0;
  for (var s = 0; s < series.length; s++) {
    if (recurring.until) {
      if (series[s] >= recurring.nextdate && series[s] <= recurring.until) {
        returnseries.push(series[s]);
      }
    } else if (recurring.count) {
      if (series[s] >= recurring.nextdate && count < recurring.count) {
        returnseries.push(series[s]);
        count++;
      }
    } else {
      if (series[s] >= recurring.nextdate) {
        returnseries.push(series[s]);
      }
    }
  }

  return returnseries;
};

NIRV.processNightly = function () {
  DEBUG && console.log('NIRV.processNightly()');
  if (
    NIRV.prefs.UICollectCompleted &&
    NIRV.prefs.UICollectCompleted.value == 'daily'
  ) {
    NIRV.collectCompletedDaily();
  }
  NIRV.processStartdatesAndDuedates();
  NIRV.spawnRecurring();
};

NIRV.taskreplace = function (original_task) {
  var task = original_task.clone();
  NIRV.taskdelete(original_task);
  task.id = uuid4();
  NIRV.tasks[task.id] = task;
  NIRV.tasks[task.id].__stale__ = true;
  NIRV.tasks[task.id].save();
  return NIRV.tasks[task.id];
};

NIRV.taskdelete = function (task) {
  var now = time();
  task.set('state', NIRV.CONST.DELETED);
  task.set('deleted', now);
  task.save();
  return task;
};

NIRV.makeacopy = function (taskid) {
  var task = NIRV.tasks[taskid].clone();

  // var new_name = function(s) {
  //     var parts = s.split(' ');
  //     var last = parts[parts.length-1];
  //     var inc = 2;
  //     var out = '';
  //     if (last == parseInt(last)) {
  //         inc = parseInt(last) + 1;
  //         out = s.substr(0,s.length-last.length) + parseInt(inc);
  //     }
  //     else {
  //         out = s + ' ' + parseInt(inc);
  //     }
  //     return out;
  // };

  var new_name = function (s) {
    return s;
    // var parts = s.split(' ');
    // var last = parts[parts.length-1];
    // var out = '';
    // if (last != 'copy') {
    //     out = s + ' copy';
    // }
    // else {
    //     out = s;
    // }
    // return out;
  };

  // TASK, REFITEM
  if (task.type == NIRV.CONST.TASK || task.type == NIRV.CONST.REFITEM) {
    if (task.name.indexOf('copy of') != 0) {
      task.name = new_name(task.name);
    }
    var init = { task: task, makeacopy: true };
    NIRV.goNewTask('li[taskid="' + taskid + '"]', init);
  }

  // PROJECT, REFLIST
  if (task.type == NIRV.CONST.PROJECT || task.type == NIRV.CONST.REFLIST) {
    if (NIRV.projectsCount() >= NIRV.user.maxprojects) {
      NIRV.promptUpgradeRequired('projects');
      return false;
    }
    if (NIRV.reflistsCount() >= NIRV.user.maxreflists) {
      NIRV.promptUpgradeRequired('reflists');
      return false;
    }
    var new_parent, new_task;
    new_parent = task.clone();
    new_parent.id = uuid4();
    new_parent.name = new_name(new_parent.name);
    new_parent.seq = time();
    new_parent.__stale__ = true;
    NIRV.tasks[new_parent.id] = new_parent;
    for (var i in NIRV.tasks) {
      if (NIRV.tasks[i] != undefined && NIRV.tasks[i].parentid == task.id) {
        new_task = NIRV.tasks[i].clone();
        new_task.id = uuid4();
        new_task.parentid = new_parent.id;
        new_task.__stale__ = true;
        NIRV.tasks[new_task.id] = new_task;
      }
    }
    NIRV.refreshAll();
  }
};

NIRV.emailthis = function (taskid) {
  var task = NIRV.tasks[taskid];
  if (task != undefined) {
    // TASK, REFITEM
    if (task.type == NIRV.CONST.TASK || task.type == NIRV.CONST.REFITEM) {
      var email = '';
      if (task.waitingfor != '') {
        var contact = NIRV.tags[task.waitingfor];
        if (contact != undefined) {
          email = contact.email;
        }
      }
      var subject = '';
      if (task.type == NIRV.CONST.TASK) {
        subject = 'ACTION: ' + task.name.trim();
        if (task.duedate) subject += '  |  due ' + ymdtomd(task.duedate) + '';
      }
      if (task.type == NIRV.CONST.REFITEM) {
        subject = 'REFERENCE: ' + task.name.trim();
      }
      var body = task.note.trim();
      var href =
        'mailto:' +
        email +
        '?subject=' +
        encodeURIComponent(subject) +
        '&body=' +
        encodeURIComponent(body);
      location.href = href;
    }

    // PROJECT, REFLIST
    if (task.type == NIRV.CONST.PROJECT || task.type == NIRV.CONST.REFLIST) {
      var email = '';
      var subject = '';
      if (task.type == NIRV.CONST.PROJECT) {
        subject = 'PROJECT: ' + task.name.trim();
        if (task.duedate) subject += '  |  due ' + ymdtomd(task.duedate) + '';
      }
      if (task.type == NIRV.CONST.REFLIST) {
        subject = 'REFERENCE: ' + task.name.trim();
      }
      var body = subject + '\n\n';
      body += task.note.trim() ? task.note.trim() + '\n\n' : '';

      if (task.type == NIRV.CONST.PROJECT) {
        var next_tasks = [];
        var later_tasks = [];
        var waiting_tasks = [];
        var scheduled_tasks = [];
        var recurring_tasks = [];
        var someday_tasks = [];
        var done_tasks = [];
        for (var i in NIRV.tasks) {
          if (NIRV.tasks[i] != undefined && NIRV.tasks[i].parentid == task.id) {
            if (
              NIRV.tasks[i].completed == 0 &&
              NIRV.tasks[i].state == NIRV.CONST.NEXT
            ) {
              next_tasks.push(NIRV.tasks[i]);
            }
            if (
              NIRV.tasks[i].completed == 0 &&
              NIRV.tasks[i].state == NIRV.CONST.LATER
            ) {
              later_tasks.push(NIRV.tasks[i]);
            }
            if (
              NIRV.tasks[i].completed == 0 &&
              NIRV.tasks[i].state == NIRV.CONST.WAITING
            ) {
              waiting_tasks.push(NIRV.tasks[i]);
            }
            if (
              NIRV.tasks[i].completed == 0 &&
              NIRV.tasks[i].state == NIRV.CONST.SCHEDULED
            ) {
              scheduled_tasks.push(NIRV.tasks[i]);
            }
            if (
              NIRV.tasks[i].completed == 0 &&
              NIRV.tasks[i].state == NIRV.CONST.RECURRING &&
              NIRV.tasks[i].recurring
            ) {
              recurring_tasks.push(NIRV.tasks[i]);
            }
            if (
              NIRV.tasks[i].completed == 0 &&
              NIRV.tasks[i].state == NIRV.CONST.SOMEDAY
            ) {
              someday_tasks.push(NIRV.tasks[i]);
            }
            if (NIRV.tasks[i].completed != 0) {
              done_tasks.push(NIRV.tasks[i]);
            }
          }
        }

        if (next_tasks.length) {
          body += '\nNEXT\n\n';
          next_tasks = sortEntitiesBy(next_tasks, 'seqp', 'asc', true);
        }
        for (var i = 0; i < next_tasks.length; i++) {
          body += '- ' + next_tasks[i].name.trim();
          if (next_tasks[i].duedate) {
            body += '  |  due ' + ymdtomd(next_tasks[i].duedate) + '';
          }
          body += '\n\n';
        }

        if (later_tasks.length) {
          body += '\nLATER\n\n';
          later_tasks = sortEntitiesBy(later_tasks, 'seqp', 'asc', true);
        }
        for (var i = 0; i < later_tasks.length; i++) {
          body += '- ' + later_tasks[i].name.trim();
          if (later_tasks[i].duedate) {
            body += '  |  due ' + ymdtomd(later_tasks[i].duedate) + '';
          }
          body += '\n\n';
        }

        if (waiting_tasks.length) {
          body += '\nWAITING\n\n';
          waiting_tasks = sortEntitiesBy(waiting_tasks, 'seqp', 'asc', true);
        }
        for (var i = 0; i < waiting_tasks.length; i++) {
          body += '- ' + waiting_tasks[i].name.trim();
          if (waiting_tasks[i].duedate) {
            body += '  |  due ' + ymdtomd(waiting_tasks[i].duedate) + '';
          }
          body += '\n\n';
        }

        if (scheduled_tasks.length) {
          body += '\nSCHEDULED\n\n';
          scheduled_tasks = sortEntitiesBy(
            scheduled_tasks,
            'startdate',
            'desc',
            true
          );
        }
        for (var i = 0; i < scheduled_tasks.length; i++) {
          body += '- ' + scheduled_tasks[i].name.trim();
          if (scheduled_tasks[i].duedate) {
            body += '  |  due ' + ymdtomd(scheduled_tasks[i].duedate) + '';
          }
          body += '\n\n';
        }

        if (recurring_tasks.length) {
          body += '\nREPEATING\n\n';
          recurring_tasks = sortEntitiesBy(
            recurring_tasks,
            'startdate',
            'desc',
            true
          );
        }
        for (var i = 0; i < recurring_tasks.length; i++) {
          body += '- ' + recurring_tasks[i].name.trim();
          body += '\n\n';
        }

        if (someday_tasks.length) {
          body += '\nSOMEDAY\n\n';
          someday_tasks = sortEntitiesBy(someday_tasks, 'seqp', 'asc', true);
        }
        for (var i = 0; i < someday_tasks.length; i++) {
          body += '- ' + someday_tasks[i].name.trim();
          if (someday_tasks[i].duedate) {
            body += '  |  due ' + ymdtomd(someday_tasks[i].duedate) + '';
          }
          body += '\n\n';
        }

        if (done_tasks.length) {
          body += '\nDONE\n\n';
          done_tasks = sortEntitiesBy(someday_tasks, 'completed', 'desc', true);
        }
        for (var i = 0; i < done_tasks.length; i++) {
          body += '- ' + done_tasks[i].name.trim() + '\n\n';
        }
      }
      if (task.type == NIRV.CONST.REFLIST) {
        var refitem_tasks = [];
        for (var i in NIRV.tasks) {
          if (NIRV.tasks[i] != undefined && NIRV.tasks[i].parentid == task.id) {
            refitem_tasks.push(NIRV.tasks[i]);
          }
        }
        refitem_tasks = sortEntitiesBy(refitem_tasks, 'seq', 'asc', true);
        for (var i = 0; i < refitem_tasks.length; i++) {
          body += '\n- ' + refitem_tasks[i].name.trim() + '\n';
          if (refitem_tasks[i].note) {
            body += '\n' + refitem_tasks[i].note.trim() + '\n\n';
          }
        }
      }

      var href =
        'mailto:' +
        email +
        '?subject=' +
        encodeURIComponent(subject) +
        '&body=' +
        encodeURIComponent(body);
      location.href = href;
    }
  }
};
