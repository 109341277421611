// --- NIRV GLOBAL OBJECT INIT ---
NIRV = {
  // CONSTANTS
  CONST: {
    // task.type
    TASK: 0,
    PROJECT: 1,
    REFITEM: 2,
    REFLIST: 3,

    // task.state
    INBOX: 0,
    NEXT: 1,
    WAITING: 2,
    SCHEDULED: 3,
    SOMEDAY: 4,
    LATER: 5,
    TRASHED: 6,
    LOGGED: 7,
    DELETED: 8,
    RECURRING: 9,
    REFERENCE: 10,
    ACTIVEPROJECT: 11,

    // task.ps
    PARALLEL: 0,
    SEQUENTIAL: 1,

    // tag.type
    CONTEXT: 0,
    AREA: 1,
    CONTACT: 2,
  },

  // PERSISTENT STORAGE (desktop)
  storage: {
    overquota: false,
    setItem: function (key, value) {
      // DEBUG && console.log('NIRV.storage.setItem('+key+')');
      try {
        if (key == 'authtoken' || key == 'u' || key == 'p') {
          return window.localStorage.setItem(key, value);
        } else if (!NIRV.storage.overquota) {
          return window.localStorage.setItem(key, value);
        }
      } catch (e) {
        console.error(e);
        NIRV.storage.overquota = true;
        var u = NIRV.storage.getItem('u');
        var authtoken = NIRV.storage.getItem('authtoken');
        NIRV.storage.clear();
        NIRV.storage.setItem('u', u);
        NIRV.storage.setItem('authtoken', authtoken);
      }
    },
    getItem: function (key) {
      // DEBUG && console.log('NIRV.storage.getItem('+key+')');
      return window.localStorage.getItem(key);
    },
    removeItem: function (key) {
      // DEBUG && console.log('NIRV.storage.removeItem('+key+')');
      return window.localStorage.removeItem(key);
    },
    key: function (key) {
      // DEBUG && console.log('NIRV.storage.key('+key+')');
      return window.localStorage.key(key);
    },
    clear: function () {
      // DEBUG && console.log('NIRV.storage.clear()');
      return window.localStorage.clear();
    },
  },

  setUser: function (user) {
    DEBUG && console.log('  NIRV.setUser() ' + user.emailaddress);
    if (JSON.stringify(NIRV.user) !== JSON.stringify(user)) {
      NIRV.user = user;
      NIRV.storage.setItem('user', JSON.stringify(NIRV.user));
    }
    Sentry.setUser({
      email: user.emailaddress,
      id: user.id,
    });
  },

  // AJAX
  setSince: function (since) {
    since = parseInt(since, 10);
    NIRV.since = isNaN(since) ? 0 : since;
    NIRV.storage.setItem('since', NIRV.since);
    DEBUG && console.log(' NIRV.since ' + NIRV.since);
  },

  // LOCALIZATION
  L: {},
  language: 'en',
  langtokens: {},

  // DESKTOP ONLY
  mousedown: 'click',
  pos_selected: false,
  highlight_taskid: false,
  dialog_position_default: {},

  mouseIsDown: false,
  keyIsDown: false,

  isElectron: navigator.userAgent.indexOf('Electron') != -1 ? true : false,
  isWindows: navigator.userAgent.indexOf('Windows') != -1 ? true : false,
  isMac: navigator.userAgent.indexOf('Macintosh') != -1 ? true : false,

  version: NIRV_version,
};

NIRV.reset = function () {
  // ENTITIES
  NIRV.user = {};
  NIRV.prefs = {};
  NIRV.tags = {};
  NIRV.tasks = {};
  NIRV.appends = {};

  // UI
  NIRV.viewport = '';
  NIRV.collapse = {
    collect: false,
    todos: false,
    activeprojects: false,
    inactiveprojects: true,
    done: false,
    tagcloud: true,
  };

  // TASK SELECT STATE
  NIRV.selecteditems = '';

  // COLLECTIONS
  NIRV.cloudtags = [];
  NIRV.tasklists = {};

  // GLOBAL RECALC
  NIRV.dimensions = {};
  NIRV.taskcounts = {
    due: {},
    action: {},
    standalone: {},
    p: {},
    l: {},
  };

  // AJAX
  NIRV.authtoken = '';
  NIRV.autosave = true;
  NIRV.ajaxconnections = 0;
  NIRV.ajax_initiated = 0;
  NIRV.ajax_counter = 0;
  NIRV.login_timestamp = 1;
  NIRV.must_sync = true;
  NIRV.nextSyncFromZero = false;
  NIRV.since = 0;
  NIRV.sync_counter = 0;
  NIRV.update_counter = 0;

  // OTHER
  NIRV.pro = false;
  NIRV.wtasks = {};
  NIRV.topn = 3;
  NIRV.moreless = {};

  // FILTERS
  NIRV.currentarea = '__ALL__';
  NIRV.currentview = 'focus';
  NIRV.currentsearch = '';
  NIRV.q = '';

  // desktop implementation
  NIRV.currentfilters = {
    tags: [],
    due: false,
    etime: -1,
    energy: -1,
  };
};

NIRV.reset();

// Base config
NIRV.ENV = 'PRODUCTION';
// NIRV.ENV = 'LOCAL-JS';

// desktop app
if (NIRV.isElectron) {
  if (NIRV.ENV === 'PRODUCTION') {
    NIRV.baseurl_account = 'https://account.nirvanahq.com/';
    NIRV.baseurl_nextapi = 'https://gc-api.nirvanahq.com/api';
    NIRV.baseurl_api = 'https://gc-api.nirvanahq.com/api';
    NIRV.baseurl_app = 'https://focus.nirvanahq.com/';
    NIRV.baseurl_www = 'https://www.nirvanahq.com/';
  }
  //
  else if (NIRV.ENV == 'LOCAL-JS') {
    NIRV.baseurl_account = 'http://m4p.local:4322/';
    NIRV.baseurl_nextapi = 'http://m4p.local:8080/api';
    NIRV.baseurl_api = 'http://m4p.local:8080/api';
    NIRV.baseurl_app = 'http://m4p.local:4321/';
    NIRV.baseurl_www = 'http://m4p.local:3000/';
  }
}

// webapp
else {
  switch (location.hostname) {
    case 'focus.nirvanahq.com':
      NIRV.baseurl_account = 'https://account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://gc-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://gc-api.nirvanahq.com/api';
      NIRV.baseurl_app = 'https://focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://www.nirvanahq.com/';
      break;

    case 'vercel-focus.nirvanahq.com':
      NIRV.baseurl_account = 'https://vercel-account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://gc-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://gc-api.nirvanahq.com/api';
      NIRV.baseurl_app = 'https://vercel-focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://www.nirvanahq.com/';
      break;

    case 'dev-vercel-focus.nirvanahq.com':
      NIRV.baseurl_account = 'https://dev-vercel-account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://dev-gc-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://dev-gc-api.nirvanahq.com/api';
      NIRV.baseurl_app = 'https://dev-vercel-focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://dev-vercel-www.nirvanahq.com/';
      break;

    case 'localhost':
    case 'm4p.local':
      NIRV.baseurl_account = 'http://m4p.local:4322/';
      NIRV.baseurl_nextapi = 'http://m4p.local:8080/api';
      NIRV.baseurl_api = 'http://m4p.local:8080/api';
      NIRV.baseurl_app = 'http://m4p.local:4321/';
      NIRV.baseurl_www = 'http://m4p.local:3000/';
      break;

    default:
      NIRV.baseurl_account = 'https://account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://gc-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://gc-api.nirvanahq.com/api';
      NIRV.baseurl_app = 'https://focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://www.nirvanahq.com/';
      break;
  }
}

// DEBUG
// stale = () => {
//   const arr = [];
//   for (var i in NIRV.tags) {
//     if (NIRV.tags[i].__stale__) {
//       arr.push(i);
//     }
//   }
//   for (var i in NIRV.tasks) {
//     if (NIRV.tasks[i].__stale__) {
//       arr.push(i);
//     }
//   }
//   console.log(arr);
//   return arr;
// };
// setInterval(() => stale(), 1000);
