NIRV.viewinit = function () {
  NIRV.currentfilters.tags = [];
  NIRV.currentfilters.due = false;
  NIRV.currentfilters.etime = -1;
  NIRV.currentfilters.energy = -1;
  NIRV.q = '';
  $('#north .q').val(NIRV.q).blur().removeClass('focus');
};

NIRV.viewinitPreserveQ = function () {
  NIRV.currentfilters.tags = [];
  NIRV.currentfilters.due = false;
  NIRV.currentfilters.etime = -1;
  NIRV.currentfilters.energy = -1;
};

NIRV.refreshEast = function () {
  DEBUG && console.log(' NIRV.refreshEast()');

  NIRV.autosave = false;

  var html = '';

  html += '   <div class="alert"></div>';

  // COLLECT
  html +=
    '   <h3 rel="east_collect" class="collect" pref="UIBCollapseCollect">';
  html += '<span class="toggle"></span>';
  html += '<span class="count"></span>';
  html += '<span class="name">Capture</span>';
  html += '</h3>';
  html += '<ul>';
  html += '<li view class="inbox" rel="inbox">';
  html += '<a class="view inbox">';
  html += '<span class="count"></span>' + NIRV.L.inbox;
  html += '</a>';
  html += '</li>';
  html += '</ul>';
  html += '<div class="sep"></div>';

  // ACTIONS
  html += '<h3 rel="actions" class="actions" pref="UIBCollapseActions">';
  html += '<span class="toggle"></span>';
  html += '<span class="count"></span>';
  html += '<span class="name">Actions</span>';
  html += '</h3>';

  html += '<ul>';

  html += '<li view class="next" rel="next">';
  html += '<a class="view next">';
  html += '<span class="count"></span>' + NIRV.L.next;
  html += '</a>';
  html == '</li>';

  if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
    html += '<li view class="later" rel="later">';
    html += '<a class="view later">';
    html += '<span class="count"></span>Later';
    html += '</a>';
    html += '</li>';
  }

  html += '<li view class="waiting" rel="waiting">';
  html += '<a class="view waiting">';
  html += '<span class="count"></span>' + NIRV.L.waiting;
  html += '</a>';
  html += '</li>';

  html += '<li view class="scheduled" rel="scheduled">';
  html += '<a class="view scheduled">';
  html += '<span class="count"></span>' + NIRV.L.scheduled;
  html += '</a>';
  html += '</li>';

  html += '<li view class="someday" rel="someday">';
  html += '<a class="view someday">';
  html += '<span class="count"></span>' + NIRV.L.someday;
  html += '</a>';
  html += '</li>';

  html += '<div class="sep"></div>';

  html += '<li view class="focus" rel="focus">';
  html += '<a class="view focus">';
  html += '<span class="count"></span>' + NIRV.L.focus;
  html += '</a>';
  html += '</li>';

  html += '</ul>';
  html += '<div class="sep"></div>';

  // PROJECTS
  html +=
    '<h3 view rel="projects" class="projects" pref="UIBCollapseProjects">';
  html += '<span class="toggle"></span>';
  html += '<span class="count"></span>';
  html += '<span class="name">Projects</span> ';
  html += '<span class="view projects"></span>';
  html += '</h3>';
  html += '<ul>';
  html += '<ul class="projects hideinactive"></ul>';
  html += '</ul>';
  html += '<div class="sep"></div>';

  // REFERENCE
  html +=
    '<h3 view rel="reflists" class="reflists" pref="UIBCollapseReference">';
  html += '<span class="toggle"></span>';
  html += '<span class="count"></span>';
  html += '<span class="name">' + NIRV.L.reference + '</span>';
  html += ' <span class="view reflists"></span>';
  html += '</h3>';
  html += '<ul class="reflists"></ul>';
  html += '<div class="sep"></div>';

  // CONTEXTS
  html +=
    '<h3 rel="east_contexts" class="contexts" pref="UIBCollapseContexts">';
  html += '<span class="toggle"></span>';
  html += '<span class="count"></span>';
  html += '<span class="name">Tags</span>';
  html += '</h3>';
  html += '<div class="tagcloud"></div>';

  // CLEANUP
  var html_cleanup = '';
  html_cleanup +=
    '<h3 rel="east_cleanup" class="cleanup" pref="UIBCollapseCleanup">';
  html_cleanup += '<span class="toggle"></span>';
  html_cleanup += '<span class="count"></span>';
  html_cleanup += '<span class="name">Cleanup</span>';
  html_cleanup += '</h3>';

  html_cleanup += '<ul class="cleanup">';

  html_cleanup += '<li view class="logbook" rel="logbook">';
  html_cleanup += '<a class="view logbook">';
  html_cleanup += '<span class="count"></span>' + NIRV.L.logbook;
  html_cleanup += '</a>';
  html_cleanup += '</li>';

  html_cleanup += '<li view class="trash empty" rel="trash">';
  html_cleanup += '<a class="view trash">';
  html_cleanup += '<span class="count"></span>' + NIRV.L.trash;
  html_cleanup += '</a>';
  html_cleanup += '</li>';

  html_cleanup += '</ul>';

  var $east = $('#east'),
    $gtd = $('#east > .gtd'),
    $cleanup = $('#east > .east_bottom');

  $gtd.empty().html(html);
  $cleanup.empty().html(html_cleanup);
  if (NIRV.prefs.UIBEastWidth) {
    $east.css('width', NIRV.prefs.UIBEastWidth.value + 'px');
    $('#north span.left').css('width', NIRV.prefs.UIBEastWidth.value + 'px');
  }

  // POPULATE TAG CLOUD
  var atags = [];
  var ctags = [];
  var tags = [];
  var task = {};
  var areas = NIRV.areas();
  var areafound = false;
  var noneflag = false;
  var a, i, t;
  for (i in NIRV.tasks) {
    task = NIRV.tasks[i];
    if (
      task != undefined &&
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.state != NIRV.CONST.DELETED &&
      !task.__inactive__()
    ) {
      if (NIRV.currentarea == '__ALL__') {
        atags = task.__tags__().split(',');
        for (t = 0; t < atags.length; t++) {
          if (atags[t] != '') {
            ctags.push(atags[t]);
          }
        }
        if (task.waitingfor != '') {
          ctags.push(task.waitingfor);
        }
      } else if (NIRV.currentarea == '__NONE__') {
        atags = task.__tags__().split(',');
        areafound = false;
        for (t = 0; t < atags.length; t++) {
          for (a = 0; a < areas.length; a++) {
            if (atags.indexOf(areas[a]) != -1) {
              areafound = true;
            }
          }
        }
        if (!areafound) {
          for (t = 0; t < atags.length; t++) {
            if (atags[t] != '') {
              ctags.push(atags[t]);
            }
          }
          if (task.waitingfor != '') {
            ctags.push(task.waitingfor);
          }
        }
      } else {
        if (task.__tags__().indexOf(',' + NIRV.currentarea + ',') != -1) {
          atags = task.__tags__().split(',');
          for (t = 0; t < atags.length; t++) {
            if (atags[t] != '') {
              ctags.push(atags[t]);
            }
          }
          if (task.waitingfor != '') {
            ctags.push(task.waitingfor);
          }
        }
      }
    }
  }
  ctags = ctags.unique();
  var tcareas = [];
  var tcelse = [];
  var indexof = ctags.indexOf(NIRV.currentarea);
  for (i = 0; i < ctags.length; i++) {
    if (NIRV.tags[ctags[i]] != undefined) {
      if (parseInt(NIRV.tags[ctags[i]].type, 10) == NIRV.CONST.AREA) {
        if (ctags[i] != NIRV.currentarea) {
          tcareas.push(ctags[i]);
        }
      } else if (i != indexof) {
        tcelse.push(ctags[i]);
      }
    }
  }

  // determine if we should show the __NONE__ tag
  var maintaskcount = 0;
  var duecount = 0;
  var tasktagcount = 0;
  var taskareatagcount = 0;
  var etimeflag = false;
  var energyflag = false;
  for (i in NIRV.tasks) {
    if (NIRV.tasks[i] != undefined) {
      maintaskcount++;
      task = NIRV.tasks[i];
      atags = task.__tags__().split(',');
      tasktagcount = 0;
      taskareatagcount = 0;
      if (task.etime != 0) {
        etimeflag = true;
      }
      if (task.energy != 0) {
        energyflag = true;
      }
      for (t = 0; t < atags.length; t++) {
        if (atags[t] != '') {
          tasktagcount++;
        }
        if (
          NIRV.tags[atags[t]] &&
          NIRV.tags[atags[t]].type == NIRV.CONST.AREA
        ) {
          taskareatagcount++;
        }
      }
      if (task.duedate != '' && task.completed == 0) {
        duecount++;
      }
      if (tasktagcount == 0) {
        noneflag = true;
      }
      if (NIRV.currentarea == '__NONE__') {
        // noneflag = false;
      } else if (
        NIRV.currentarea != '__ALL__' &&
        tasktagcount == taskareatagcount
      ) {
        noneflag = true;
      }
    }
  }

  tcareas.sort(isort);
  tcelse.sort(isort);
  // ctags.sort(isort);
  NIRV.cloudtags = ctags;
  var gtag = NIRV.currentview[0] == 'g' ? NIRV.currentview.substr(1) : '';
  var xtag = function (tag) {
    return tag == gtag ? 'on' : '';
  };

  var tagcloud_html = '';
  tagcloud_html += '<ul class="tags">';
  tagcloud_html +=
    '<li class="tag all ' + xtag('__ALL__') + '" rel="g__ALL__">All</li>';
  for (var i = 0; i < tcareas.length; i++) {
    tagcloud_html +=
      '<li class="tag area ' +
      xtag(tcareas[i]) +
      '" rel="g' +
      tcareas[i] +
      '">' +
      tcareas[i] +
      '</li>';
  }
  for (var i = 0; i < tcelse.length; i++) {
    tagcloud_html +=
      '<li class="tag ' +
      xtag(tcelse[i]) +
      '" rel="g' +
      tcelse[i] +
      '">' +
      tcelse[i] +
      '</li>';
  }
  if (noneflag) {
    tagcloud_html +=
      '<li class="tag none ' +
      xtag('__NONE__') +
      '" rel="g__NONE__">&mdash;</li>';
  }
  tagcloud_html += '</ul>';
  $('#east div.tagcloud').html(tagcloud_html);

  // RESTORE COLLAPSE STATE H3
  var UICollapsePrefs = [
    'UIBCollapseCollect',
    'UIBCollapseActions',
    'UIBCollapseProjects',
    'UIBCollapseReference',
    'UIBCollapseContexts',
    'UIBCollapseCleanup',
  ];
  for (var i = 0, len = UICollapsePrefs.length; i < len; i++) {
    var prefName = UICollapsePrefs[i];
    if (NIRV.prefs[prefName] && NIRV.prefs[prefName].value == 1) {
      $('#east h3[pref=' + prefName + ']').addClass('collapsed');
    }
  }

  // BUILD ACTIVE PROJECTS LISTING
  var projects = NIRV.projects(NIRV.currentarea);
  // for (var i = 0; i < projects.length; i++) {
  //     if (projects[i].completed != 0) {
  //         projects.splice(i,1);
  //         i--;
  //     }
  // }
  var activeprojects = [];
  var laterprojects = [];
  var scheduledprojects = [];
  var somedayprojects = [];
  var completedprojects = [];
  var activecount = 0;
  var inactivecount = 0;

  for (var i = 0; i < projects.length; i++) {
    var project = projects[i];
    if (project.completed == 0 && project.state == NIRV.CONST.ACTIVEPROJECT) {
      activecount++;
      activeprojects.push(project);
    } else if (project.completed == 0 && project.state == NIRV.CONST.LATER) {
      inactivecount++;
      laterprojects.push(project);
    } else if (
      project.completed == 0 &&
      project.state == NIRV.CONST.SCHEDULED
    ) {
      inactivecount++;
      scheduledprojects.push(project);
    } else if (project.completed == 0 && project.state == NIRV.CONST.SOMEDAY) {
      inactivecount++;
      somedayprojects.push(project);
    } else if (project.completed != 0 && project.state != NIRV.CONST.LOGGED) {
      inactivecount++;
      completedprojects.push(project);
    }
  }

  if (NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha') {
    activeprojects = sortEntitiesBy(activeprojects, 'name', 'asc', false);
    laterprojects = sortEntitiesBy(laterprojects, 'name', 'asc', false);
    scheduledprojects = sortEntitiesBy(scheduledprojects, 'name', 'asc', false);
    somedayprojects = sortEntitiesBy(somedayprojects, 'name', 'asc', false);
    completedprojects = sortEntitiesBy(completedprojects, 'name', 'asc', false);
  } else {
    scheduledprojects = sortEntitiesBy(
      scheduledprojects,
      'startdate',
      'asc',
      true
    );
    completedprojects = sortEntitiesBy(
      completedprojects,
      'completed',
      'desc',
      true
    );
  }

  // ACTIVE PROJECTS
  for (var i = 0; i < activeprojects.length; i++) {
    var task = activeprojects[i];
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.state != NIRV.CONST.DELETED
    ) {
      var due = task.duedate != '' && task.duedate <= today() ? 'due' : '';
      $('#east ul.projects').append(
        '<li view class="project activeproject ' +
          due +
          '" rel="p' +
          task.id +
          '" taskid="' +
          task.id +
          '"><a class="view project" pid="' +
          task.id +
          '"><span class="count"></span>' +
          task.name +
          '&nbsp;</a></li>'
      );
    }
  }

  // INACTIVE PROJECTS
  for (var i = 0; i < laterprojects.length; i++) {
    var task = laterprojects[i];
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.state != NIRV.CONST.DELETED
    ) {
      var due = task.duedate != '' && task.duedate <= today() ? 'due' : '';
      $('#east ul.projects').append(
        '<li view class="project later ' +
          due +
          '" rel="p' +
          task.id +
          '" taskid="' +
          task.id +
          '"><a class="view project" pid="' +
          task.id +
          '"><span class="count"></span>' +
          task.name +
          '&nbsp;</a></li>'
      );
    }
  }

  // SCHEDULED PROJECTS
  for (var i = 0; i < scheduledprojects.length; i++) {
    var task = scheduledprojects[i];
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.state != NIRV.CONST.DELETED
    ) {
      var due = task.duedate != '' && task.duedate <= today() ? 'due' : '';
      $('#east ul.projects').append(
        '<li view class="project scheduled ' +
          due +
          '" rel="p' +
          task.id +
          '" taskid="' +
          task.id +
          '"><a class="view project" pid="' +
          task.id +
          '"><span class="count"></span>' +
          task.name +
          '&nbsp;</a></li>'
      );
    }
  }

  // SOMEDAY PROJECTS
  for (var i = 0; i < somedayprojects.length; i++) {
    var task = somedayprojects[i];
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.state != NIRV.CONST.DELETED
    ) {
      var due = task.duedate != '' && task.duedate <= today() ? 'due' : '';
      $('#east ul.projects').append(
        '<li view class="project someday ' +
          due +
          '" rel="p' +
          task.id +
          '" taskid="' +
          task.id +
          '"><a class="view project" pid="' +
          task.id +
          '"><span class="count"></span>' +
          task.name +
          '&nbsp;</a></li>'
      );
    }
  }

  // COMPLETED PROJECTS
  for (var i = 0; i < completedprojects.length; i++) {
    var task = completedprojects[i];
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.state != NIRV.CONST.DELETED
    ) {
      var due = task.duedate != '' && task.duedate <= today() ? 'due' : '';
      $('#east ul.projects').append(
        '<li view class="project completed ' +
          due +
          '" rel="p' +
          task.id +
          '" taskid="' +
          task.id +
          '"><a class="view project" pid="' +
          task.id +
          '"><span class="count"></span>' +
          task.name +
          '&nbsp;</a></li>'
      );
    }
  }

  if (inactivecount == 0) {
    // $('#east h3.projects span.name').html('All Projects');
  } else {
    // $('#east h3.projects span.name').html(NIRV.L.activeprojects)
    $('#east ul.projects').append(
      '<a class="toggleinactive moreorless collapsed" rel="inactiveprojects">show inactive projects</a>'
    );
  }

  if (projects.length == 0) {
    $('#east li.projects span.toggle').css('visibility', 'hidden');
  } else {
    $('#east li.projects span.toggle').css('visibility', 'visible');
  }

  // BUILD UP REFERENCE LIST SECTION
  var reflists = NIRV.reflists(NIRV.currentarea);

  if (NIRV.prefs.UISortReflists && NIRV.prefs.UISortReflists.value == 'alpha') {
    reflists = sortEntitiesBy(reflists, 'name', 'asc', false);
  }

  for (var i = 0; i < reflists.length; i++) {
    var task = reflists[i];
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.state != NIRV.CONST.DELETED
    ) {
      $('#east ul.reflists').append(
        '<li view class="reflist" rel="l' +
          task.id +
          '" taskid="' +
          task.id +
          '"><a class="view reflist" lid="' +
          task.id +
          '"><span class="count"></span>' +
          task.name +
          '&nbsp;</a></li>'
      );
    }
  }

  // RESTORE UI STATE SELECTED
  if (NIRV.q == '') {
    $('#east li[rel="' + NIRV.currentview + '"]').addClass('on');
  }

  // RESTORE COLLAPSE STATE ALL/INACTIVE PROJECTS
  if (!NIRV.collapse.inactiveprojects) {
    $('a.toggleinactive[rel=inactiveprojects]').trigger(
      $.Event(NIRV.mousedown)
    );
  }

  // RESTORE PROJECTS ARROW
  if (NIRV.currentview == 'projects') {
    $('h3.projects').addClass('on');
  }

  // RESTORE REFERENCE ARROW
  if (NIRV.currentview == 'reflists') {
    $('h3.reflists').addClass('on');
  }

  // RESTORE TASK COUNTS
  NIRV.updateCounts();

  // NOTICE: NO PROJECTS TO DISPLAY
  if (NIRV.taskcounts.section.projects == 0) {
    $('#east span.noprojects').remove();
    $('#east ul.projects:first').append(
      '<span class="noprojects">no projects to display</span>'
    );
  }

  // NOTICE: NO REFLISTS TO DISPLAY
  if (NIRV.taskcounts.section.reflists == 0) {
    $('#east span.noreflists').remove();
    $('#east ul.reflists:first').append(
      '<span class="noreflists">no reference lists to display</span>'
    );
  }

  $('#east ul.projects').sortable({
    distance: 5,
    zindex: 99999,
    opacity: 0.9,
    placeholder: 'placeholder',
    forcePlaceholderSize: true,
    sort: function (event, ui) {
      // DEBUG && console.log(ui[item][0].class);
      // DEBUG && console.log(ui.helper.context.className);
    },
    stop: function () {
      var position = 1;
      var that = this;
      setTimeout(function () {
        $(that)
          .children('li.project')
          .each(function () {
            NIRV.tasks[$(this).find('a[pid]').attr('pid')].set(
              'seq',
              position++
            );
          });

        NIRV.recalcWtasks();
        NIRV.refreshEast();
        NIRV.reflow();
        if (!NIRV.editing()) {
          if (NIRV.currentview == 'focus') {
            $('#east a.focus').trigger($.Event(NIRV.mousedown));
          }
          if (NIRV.currentview == 'next') {
            $('#east a.next').trigger($.Event(NIRV.mousedown));
          }
          NIRV.refreshMain();
        }
      }, 5);
    },
  });

  $('#east ul.reflists').sortable({
    distance: 5,
    opacity: 0.9,
    placeholder: 'placeholder',
    forcePlaceholderSize: true,
    sort: function (event, ui) {
      // DEBUG && console.log(ui[item][0].class);
      // DEBUG && console.log(ui.helper.context.className);
    },
    stop: function () {
      var position = 1;
      var that = this;
      setTimeout(function () {
        $(that)
          .children('li.reflist')
          .each(function () {
            NIRV.tasks[$(this).find('a[lid]').attr('lid')].set(
              'seq',
              position++
            );
          });

        NIRV.recalcWtasks();
        NIRV.refreshEast();
        NIRV.reflow();
        if (!NIRV.editing()) {
          if (NIRV.currentview == 'focus') {
            $('#east a.focus').trigger($.Event(NIRV.mousedown));
          }
          if (NIRV.currentview == 'next') {
            $('#east a.next').trigger($.Event(NIRV.mousedown));
          }
          NIRV.refreshMain();
        }
      }, 5);
    },
  });

  var dragged;
  var droppedon;

  NIRV.mouseisovereastbottom = function () {
    var top = $('#east .east_bottom').offset().top;
    var bottom = top + $('#east .east_bottom').height();
    if (NIRV.mouseY >= top && NIRV.mouseY <= bottom) {
      // DEBUG && console.log('mouseisovereastbottom');
      return true;
    } else {
      return false;
    }
  };

  $('#east li.trash').droppable({
    accept: '.drag, li.task, #east li.project, #east li.reflist',
    hoverClass: 'hover',
    tolerance: 'pointer',
    drop: function (e, ui) {
      var that = this;
      $('.ui-selected').removeClass('ui-selected');
      if (NIRV.selecteditems == undefined || NIRV.selecteditems.length == 0) {
        dragged = ui.draggable.attr('taskid');
      } else {
        dragged = NIRV.selecteditems;
      }
      droppedon = $(that).attr('rel');
      DEBUG && console.log(droppedon + ' received ' + dragged);
      NIRV.dropTasksOnList(dragged, droppedon);
      return false;
    },
  });

  $('#east li.logbook').droppable({
    accept: '.drag, li.task0:not(.repeating), li.project, #east li.project',
    hoverClass: 'hover',
    tolerance: 'pointer',
    drop: function (e, ui) {
      $('.ui-selected').removeClass('ui-selected');
      if (NIRV.selecteditems == undefined || NIRV.selecteditems.length == 0) {
        dragged = ui.draggable.attr('taskid');
      } else {
        dragged = NIRV.selecteditems;
      }
      droppedon = $(this).attr('rel');
      DEBUG && console.log(droppedon + ' received ' + dragged);
      NIRV.dropTasksOnList(dragged, droppedon);
      return false;
    },
  });

  $('#east li.inbox, #east li.focus').droppable({
    accept: '.drag, li.task, #east li.project, #east li.reflist',
    hoverClass: 'hover',
    tolerance: 'pointer',
    drop: function (e, ui) {
      if (NIRV.mouseisovereastbottom()) {
        return false;
      }
      var that = this;
      $('.ui-selected').removeClass('ui-selected');
      if (NIRV.selecteditems == undefined || NIRV.selecteditems.length == 0) {
        dragged = ui.draggable.attr('taskid');
      } else {
        dragged = NIRV.selecteditems;
      }
      droppedon = $(that).attr('rel');
      DEBUG && console.log(droppedon + ' received ' + dragged);
      NIRV.dropTasksOnList(dragged, droppedon);
      return false;
    },
  });

  $(
    '#east li.next, #east li.later, #east li.scheduled, #east li.someday'
  ).droppable({
    accept: '.drag, li.task0, li.project, #east li.project',
    hoverClass: 'hover',
    tolerance: 'pointer',
    drop: function (e, ui) {
      if (NIRV.mouseisovereastbottom()) {
        return false;
      }
      $('.ui-selected').removeClass('ui-selected');
      if (NIRV.selecteditems == undefined || NIRV.selecteditems.length == 0) {
        dragged = ui.draggable.attr('taskid');
      } else {
        dragged = NIRV.selecteditems;
      }
      droppedon = $(this).attr('rel');
      DEBUG && console.log(droppedon + ' received ' + dragged);
      NIRV.dropTasksOnList(dragged, droppedon);
      return false;
    },
  });

  $('#east li.waiting, #east li.projects, #east li.project').droppable({
    accept: '.drag, li.task0',
    hoverClass: 'hover',
    tolerance: 'pointer',
    drop: function (e, ui) {
      if (NIRV.mouseisovereastbottom()) {
        return false;
      }
      $('.ui-selected').removeClass('ui-selected');
      if (NIRV.selecteditems == undefined || NIRV.selecteditems.length == 0) {
        dragged = ui.draggable.attr('taskid');
      } else {
        dragged = NIRV.selecteditems;
      }
      droppedon = $(this).attr('rel');
      DEBUG && console.log(droppedon + ' received ' + dragged);
      NIRV.dropTasksOnList(dragged, droppedon);
      return false;
    },
  });

  $('#east li.reflist').droppable({
    accept: '.drag, li.task0, li.refitem',
    hoverClass: 'hover',
    tolerance: 'pointer',
    drop: function (e, ui) {
      if (NIRV.mouseisovereastbottom()) {
        return false;
      }
      $('.ui-selected').removeClass('ui-selected');
      if (NIRV.selecteditems == undefined || NIRV.selecteditems.length == 0) {
        dragged = ui.draggable.attr('taskid');
      } else {
        dragged = NIRV.selecteditems;
      }
      droppedon = $(this).attr('rel');
      DEBUG && console.log(droppedon + ' received ' + dragged);
      NIRV.dropTasksOnList(dragged, droppedon);
      return false;
    },
  });

  $('#east h3.projects').droppable({
    accept: '.drag, li.task, #east li.reflist',
    hoverClass: 'hover',
    tolerance: 'pointer',
    drop: function (e, ui) {
      if (NIRV.mouseisovereastbottom()) {
        return false;
      }
      $('.ui-selected').removeClass('ui-selected');
      if (NIRV.selecteditems == undefined || NIRV.selecteditems.length == 0) {
        dragged = ui.draggable.attr('taskid');
      } else {
        dragged = NIRV.selecteditems;
      }
      droppedon = $(this).attr('rel');
      DEBUG && console.log(droppedon + ' received ' + dragged);
      NIRV.dropTasksOnList(dragged, droppedon);
      return false;
    },
  });

  $('#east h3.reflists').droppable({
    accept: '.drag, li.task, #east li.project',
    hoverClass: 'hover',
    tolerance: 'pointer',
    drop: function (e, ui) {
      if (NIRV.mouseisovereastbottom()) {
        return false;
      }
      $('.ui-selected').removeClass('ui-selected');
      if (NIRV.selecteditems == undefined || NIRV.selecteditems.length == 0) {
        dragged = ui.draggable.attr('taskid');
      } else {
        dragged = NIRV.selecteditems;
      }
      droppedon = $(this).attr('rel');
      DEBUG && console.log(droppedon + ' received ' + dragged);
      NIRV.dropTasksOnList(dragged, droppedon);
      return false;
    },
  });

  // RESIZE
  if ($('#east').resizable() != undefined) {
    $('#east').resizable('destroy');
  }
  $('#east').resizable({
    handles: 'e',
    minWidth: 240,
    maxWidth: 600,
    resize: function () {
      $('#north span.left').css('width', $('#east').innerWidth());
      // NIRV.reflow();
    },
    stop: function () {
      var width = $('#east').innerWidth();
      if (width < 240) {
        width = 240;
      }
      if (width > 600) {
        width = 600;
      }
      NIRV.setPref('UIBEastWidth', width, true);
    },
  });

  NIRV.autosave = true;
};
