NIRV.refreshMain = function (callback) {
  DEBUG && console.log(' NIRV.refreshMain()');

  NIRV.autosave = false;

  var highlight_taskids = NIRV.highlighted_tasks();
  $('#main .tasklist').each(function () {
    var key = $(this).attr('key');
    NIRV.tasklists[key]?.recalc();
    NIRV.tasklists[key]?.redraw();
  });
  NIRV.highlight_tasks(highlight_taskids);

  NIRV.refreshHelp();
  NIRV.refreshRapidEntry();
  NIRV.refreshTips();
  NIRV.hideEmptyTasklists();
  NIRV.sortable();

  if (typeof callback == 'function') {
    callback();
  }

  NIRV.autosave = true;
};

NIRV.refreshHelp = function () {
  DEBUG && VERBOSE && console.log('  NIRV.refreshHelp()');

  var html = '';

  html += '<span class="inner">';
  html += '<span class="closex">x</span>';

  html +=
    "<span style='width:160px;float:right; border-left: 1px dotted #cc9; margin: 2.4em 0 1em 2em; padding-left: 1.5em;'><i>You can turn this help panel on/off at any time by pressing</i> <span class='hotkey'>Shift</span>+<span class='hotkey'>h</span></span>";

  if (NIRV.q != '') {
    html +=
      '<h1>Search Tips</h1>Nirvana looks for matches in item names, notes and tags.<br>';
  } else if (NIRV.currentview == 'inbox') {
    html +=
      "<h1>Inbox</h1>The Inbox is the place to quickly get thoughts out of your head and into Nirvana. Once your mind is clear and everything is visible in front of you, decide where each task should go. Is it a next action? Drag it to Next in the left navi. Want to schedule it for the future? Drag it to Scheduled, and so on.<br><br>Press <span class='hotkey'>n</span> to create a new action &mdash; or click New Item in the top navi<br>Press <span class='hotkey'>i</span> to create a new action in your Inbox from anywhere else";
  } else if (NIRV.currentview == 'focus') {
    html +=
      "<h1>Focus</h1>Actions that require your immediate attention appear in your Focus list. Actions that are Due always appear here, as do Scheduled tasks when their start date rolls around. You can also click the star icon on tasks in other lists to bring them into or out of Focus. Keeping your Focus list short and limited only to what's really important today will help you stay focused on getting things done.<br><br>Press <span class='hotkey'>n</span> to create a new action &mdash; or click New Item in the top navi";
  } else if (NIRV.currentview == 'next') {
    html +=
      "<h1>Next</h1>Place all actionable to dos in Next so that this becomes your one stop shop for determining the next action. Conversely, anything that is not actionable should be moved elsewhere. Actionable tasks from your Active Projects also appear here. Use the asterisk/widget icon in the filter bar (above) to tailor how project tasks are displayed within Next.<br><br>Press <span class='hotkey'>n</span> to create a new action &mdash; or click New Item in the top navi";
  } else if (NIRV.currentview == 'later') {
    html +=
      '<h1>Later</h1>The Later list is a hold-over from a previous version of Nirvana, and is provided primarily for backwards compatability. We recommend disabling Later as it is not part of GTD®. You can do this via Account Settings.';
  } else if (NIRV.currentview == 'waiting') {
    html +=
      "<h1>Waiting</h1>Actions that are on hold, pending action by someone else, should be placed in Waiting. Be sure to check this list periodically to follow up on tasks in waiting. As soon as you are able to move foward on a task again, be sure to put it back in Next — or mark as complete if the task was done.<br><br>Press <span class='hotkey'>n</span> to create a new action &mdash; or click New Item in the top";
  } else if (NIRV.currentview == 'scheduled') {
    html +=
      "<h1>Scheduled</h1>Actions you wish to defer acting on until a later date should be placed here. Scheduled tasks are moved to your Next list automatically on the date you specify, and will also appear starred in your Focus list to capture your attention. For Scheduled tasks that are set to repeat, copies will created and placed in Next on the dates you specify.<br><br>Press <span class='hotkey'>n</span> to create a new action &mdash; or click New Item in the top navi";
  } else if (NIRV.currentview == 'someday') {
    html +=
      "<h1>Someday</h1>Projects and Actions you may wish to tackle at some point, but are not pressing in the near term, should be placed in Someday. Move items out of Someday only when you are ready to start working on them. This helps to keep your Next actions list at a manageable size so that you can easily see what truly needs to get done.<br><br>Press <span class='hotkey'>n</span> to create a new action &mdash; or click New Item in the top navi";
  } else if (NIRV.currentview == 'projects') {
    html +=
      "<h1>Projects</h1>A project should be created if completing something requires multiple action steps. If the actions can be done in any order it is considered a parallel project. If the action steps need to happen in a particular order it is a sequential project. You can convert existing actions into projects by dragging them onto My Projects in the left navi.<br><br>Press <span class='hotkey'>p</span> to create a new project &mdash; or click New Item in the top navi";
  } else if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
    html +=
      "<h1>Project</h1>If tasks for this project can be done in any order, you should set this project to be Parallel. Otherwise, if tasks should be done in sequence, one after the other, make it Sequential.<br><br>Press <span class='hotkey'>n</span> to create a new action &mdash; or click New Item in the top navi";
  } else if (NIRV.currentview == 'logbook') {
    html +=
      '<h1>Logbook</h1>The Logbook lists Projects and Actions that have been completed (checked) along with the date they were completed. Drag completed items to the Logbook from other lists when you no longer want to see them there, or click Collect Completed Items to move them all in one shot.';
  } else if (NIRV.currentview == 'trash') {
    html +=
      '<h1>Trash</h1>Drag projects or tasks that you wish to remove from Nirvana to the Trash. When you are ready to permanently erase them click Empty Trash. You can also do this by right-clicking on Trash in the left navi.<br>';
  } else if (NIRV.currentview == 'reflists') {
    html +=
      "<h1>Reference Lists</h1>A Reference List is a container for non-actionable reference items, such as links to reference material on the web that you may wish to refer to beyond the completion of an action or a project. Tip: Reference Lists can be converted into a Projects by dragging and dropping them onto Projects, so they can also be used as Project templates. To facilitate this you can make a copy of a reference list before converting it into a project by right-clicking on a Reference List and selecting 'Make a copy'.<br><br>Press <span class='hotkey'>L</span> to create a new list &mdash; or click Reference List in the top navi";
  } else if (NIRV.currentview[0] == 'l') {
    html +=
      "<h1>Reference Items</h1>Capture reference material that you may wish to refer to beyond the completion of an action (or a project) in a reference item. Tip: if using a Reference List as a Project template, the reference items below will become next-actions after converting a Reference List into a Project via right-click &rarr; Make a copy.<br><br>Press <span class='hotkey'>n</span> to create a new reference item";
  } else if (NIRV.currentview[0] == 'g') {
    html +=
      "<h1>Using the Tag Cloud</h1>Selecting a tag from the Tag Cloud displays all tasks and projects containing that tag, regardless of where it's been filed. For example: if you've tagged tasks that involve calling someone with 'Phone', clicking 'Phone' in the Tag Cloud will list all of the calls you need to make on one page, whether it's in Next, Waiting, Scheduled or Someday. From there you can quickly move tasks between lists, or simply take action.<br>";
  }

  html += '</span>';

  if (
    NIRV.prefs.UIBShowSidebarHelp &&
    NIRV.prefs.UIBShowSidebarHelp.value == 1
  ) {
    $('#help').show();
  } else {
    $('#help').hide();
  }

  $('#help').html(html);
};

NIRV.refreshRapidEntry = function () {
  var html = `
  <form 
    class="rapidentry" 
    autocomplete="off" 
    onsubmit="return false;">
    <input 
      class="rapidentry" 
      type="text" 
      name="r2" 
      value="" 
      placeholder="Rapid Entry  —  type here and hit enter / or esc" 
      autocomplete="off" />
  </form>
  <div class="clear"></div>`;
  $('#rapidentry').html(html);

  if (
    NIRV.prefs.UIEnableRapidEntry &&
    NIRV.prefs.UIEnableRapidEntry.value == 1 &&
    NIRV.currentview != 'trash' &&
    NIRV.currentview != 'search'
  ) {
    $('#rapidentry').show();
  } else {
    $('#rapidentry').hide();
  }
};

NIRV.collapseCompletedTasklists = function () {
  var projects = NIRV.projects();
  for (var i = 0; i < projects.length; i++) {
    NIRV.collapse['p' + projects[i].id + ':project.completed'] = true;
    NIRV.collapse['p' + projects[i].id + ':project.logged'] = true;
  }
  NIRV.collapse['inbox:inbox.completed'] = true;
  NIRV.collapse['next:next.completed'] = true;
  NIRV.collapse['later:later.completed'] = true;
  NIRV.collapse['scheduled:scheduled.completed'] = true;
  NIRV.collapse['someday:someday.completed'] = true;
  NIRV.collapse['projects:projects.completed'] = true;
};

NIRV.sortable = function () {
  DEBUG && console.log(' NIRV.sortable()');
  $('#main .tasklist ul.tasks:not(.prompt)').sortable({
    placeholder: 'placeholder',
    forcePlaceholderSize: true,
    distance: 5,
    cursor: 'default',
    tolerance: 'pointer',
    cancel: '.edit',
    start: function () {
      NIRV.autosave = false;
    },
    stop: function () {
      NIRV.autosave = true;
    },
    receive: function (event, ui) {
      var listid = $(event.target).parent().attr('listid');
      var taskid = $(ui.item[0]).attr('taskid');
      var task = NIRV.tasks[taskid];
      if (listid == 'inbox') {
        task.set('state', 0);
      } else if (listid == 'next') {
        task.set('state', 1);
      } else if (listid == 'someday') {
        task.set('state', 4);
      } else if (listid == 'trash') {
        task.set('state', 6);
      }
    },
    update: function (event, ui) {
      var sortby = $(this).attr('sortby');
      if (sortby != undefined && sortby.slice(0, 3) != 'seq') {
        return false;
      }
      NIRV.reseqeuence_and_save($(ui.item));
    },
  });
};

NIRV.reseqeuence_and_save = debounce(function (items) {
  // DEBUG && console.log(Date.now() + ' NIRV.reseqeuence_and_save() ')
  // DEBUG && console.log(items);
  // DEBUG && console.log(items.length);

  var tasklists = {},
    taskids = [],
    i,
    $ul,
    sortby,
    position;

  for (i = 0; i < items.length; i++) {
    // DEBUG && console.log(items[i]);
    tasklists[$(items[i]).closest('.tasklist').attr('key')] = $(
      items[i]
    ).closest('.ui-sortable');
    taskids.push($(items[i]).attr('taskid'));
  }

  for (i in tasklists) {
    // DEBUG && console.log($(tasklists[i]).closest('.tasklist').attr('key'));

    $ul = $(tasklists[i]);
    sortby = $ul.attr('sortby');
    // DEBUG && console.log(sortby);

    position = 1;
    if (sortby != undefined && sortby.slice(0, 3) == 'seq') {
      $ul.children('li.task').each(function () {
        if (
          NIRV.tasks[$(this).attr('taskid')] &&
          typeof NIRV.tasks[$(this).attr('taskid')].set == 'function'
        ) {
          NIRV.tasks[$(this).attr('taskid')].set(sortby, position++);
        }
      });
    }
  }

  NIRV.recalcWtasks();
  // NIRV.refreshMain()
  NIRV.highlight_tasks(taskids);

  // update active projects in #east
  if (NIRV.currentview == 'projects' || NIRV.currentview == 'reflists') {
    NIRV.refreshEast();
    NIRV.reflow();
  }
}, 200);

NIRV.scrollLiIntoView = function ($li) {
  if ($li.length != 0) {
    var north_cbar_height =
      $('#north').outerHeight() + $('#cbar').outerHeight();
    var viewable_top = $(window).scrollTop() + north_cbar_height;
    var viewable_bottom = $(window).height() + $(window).scrollTop();
    var li_top = $li.offset().top;
    var li_bottom = li_top + $li.outerHeight();
    var hidden_px = 0;
    var window_scrolltop = 0;

    if (li_bottom > viewable_bottom) {
      hidden_px = li_bottom - viewable_bottom;
      window_scrolltop = $(window).scrollTop() + hidden_px + 60;
      $('html,body').animate(
        {
          scrollTop: window_scrolltop,
        },
        100
      );
    } else if (li_top < viewable_top) {
      hidden_px = viewable_top - li_top;
      window_scrolltop = $(window).scrollTop() - hidden_px - 40;
      $('html,body').animate(
        {
          scrollTop: window_scrolltop,
        },
        100
      );
    }
  }
};

NIRV.highlight_tasks = function (taskids) {
  DEBUG && console.log(' NIRV.highlight_tasks(' + taskids + ')');
  $('.ui-selected').removeClass('ui-selected');
  if (!$('html').hasClass('editing')) {
    if (taskids === undefined) {
      // TBD - highlight previously highlighted (somehow)
    } else if (taskids === false) {
      // removes highlighting
    } else {
      for (var i = 0; i < taskids.length; i++) {
        $('#main li.task[taskid=' + taskids[i] + ']').addClass('ui-selected');
      }
    }
  }
  NIRV.draggable();
};

NIRV.highlighted_tasks = function () {
  var taskids = [];
  $('#main li.task.ui-selected').each(function () {
    taskids.push($(this).attr('taskid'));
  });
  return taskids;
};

NIRV.refreshMultiselectDataPos = function () {
  DEBUG && VERBOSE && console.log('NIRV.refreshMultiselectDataPos()');
  var xx = 0;
  $('#main li.task:visible').each(function () {
    $(this).attr('data-pos', xx++);
  });
};

NIRV.alert = function (message) {
  message = message || 'Alert';
  var html = '';
  html += '<p>' + message + '</p>';
  $('#alert').html(html);
  $('#alert').dialog({
    autoOpen: true,
    closeOnEscape: true,
    dialogClass: 'NIRV_alert',
    title: '',
    height: 'auto',
    minHeight: 0,
    width: 360,
    modal: true,
    resizable: false,
    position: NIRV.dialog_position_default,
    buttons: {
      OK: function () {
        $(this).dialog('close');
      },
    },
    open: function () {},
    close: function () {},
  });
};

NIRV.confirm = function (message, callback) {
  message = message || 'Are you sure?';
  var html = '';
  html += '<p>' + message + '</p>';
  $('#confirm').html(html);
  $('#confirm').dialog({
    autoOpen: true,
    closeOnEscape: true,
    dialogClass: 'NIRV_confirm',
    title: '',
    height: 'auto',
    minHeight: 0,
    width: 360,
    modal: true,
    resizable: false,
    position: NIRV.dialog_position_default,
    buttons: {
      OK: function () {
        callback();
        $(this).dialog('close');
      },
      Cancel: function () {
        $(this).dialog('close');
      },
    },
    open: function () {},
    close: function () {},
  });
};
